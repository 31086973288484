import React, { useEffect } from "react";
import styled from "@emotion/styled";
import ButtonComponent from "./Button.js";
import { useNavigate } from "react-router-dom";
const TopBannerComponent = ({
  fix = true,
  title,
  freeDay = 0,
  setOpenPlanSelect,
}) => {
  const navigate = useNavigate();

  return (
    <Component fix={`${fix}`}>
      <div>
        <span className="main">
          <img src="/assets/icons/alert-circle.svg"></img>
          {title === "결제하기"
            ? "수수료 미결제건이 존재합니다."
            : "무료 플랜이 곧 만료됩니다."}
        </span>
        <em
          dangerouslySetInnerHTML={{
            __html:
              title === "결제하기"
                ? "10일 이상 미결제시 표시되며 30일 이상 미결제시 기능이 제한됩니다."
                : `무료 플랜 기간이 <span>${
                    61 - freeDay
                  }</span>일 남았습니다. <br /> 무료 플랜 만료 이후에는 사용이 제한되며 유료 플랜 전환을 하시면 지금과 같이 제한없이 사용하실 수 있습니다.`,
          }}
        ></em>
      </div>

      <ButtonComponent
        style={{ lineHeight: "32px" }}
        onClick={(event) => {
          event.preventDefault();
          if (title === "결제하기") {
            navigate("/setting/payment-information");
          } else {
            setOpenPlanSelect((prevState) => ({ ...prevState, module: true }));
            // navigate("/upgrade-plan", {
            //   state: {
            //     isFree: true,
            //   },
            // });
          }
        }}
      >
        {title}
      </ButtonComponent>
    </Component>
  );
};
const Component = styled.div`
  padding: 16px 25px;
  box-sizing: border-box;
  background-color: #373737;
  color: #fff;
  min-height: 100px;
  position: ${(props) => (props.fix === "true" ? "fixed" : "unset")};
  top: 0;
  left: 250px;
  right: 0;
  width: ${(props) => (props.fix === "true" ? "calc(100vw - 250px)" : "unset")};
  z-index: 9999;
  font-size: 17px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  span.main {
    display: flex;
    align-items: center;
    column-gap: 5px;
    img {
      width: 18px;
      height: 18px;
    }
  }

  em {
    font-size: 14px;
    font-weight: 400;
    color: rgb(255, 249, 0);
    margin-left: 25px;
    margin-top: 10px;
    display: block;

    span {
      color: #ff3838;
    }
  }
`;
export default TopBannerComponent;
