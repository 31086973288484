import React, { useCallback, useEffect, useState } from "react";
import dayjs from "dayjs";
import { InputBox, WidthStyle } from "../../library/styled-components";
import styled from "@emotion/styled";
import { useForm } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import {
  createLaborerAttendance,
  getLaborerAttendance,
} from "../../library/admin-react-query";
import { numberOnly } from "../../bin/common";

const NewAttendanceForm = ({
  selectedAllDates,
  formRef,
  formFinish,
  user_seq,
  ...props
}) => {
  // useForm
  const [formData, setFormData] = useState(null);
  const {
    register,
    getValues,
    handleSubmit,
    setFocus,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {},
  });
  const { mutate: getLaborerAttendanceItem, data: getLaborerAttendanceData } =
    useMutation({
      mutationFn: getLaborerAttendance,
      mutationKey: ["getLaborerAttendance"],
      onSuccess: (data) => {
        console.log(data);
        data?.attendanceData?.map((e, i) => {
          const workDate = e.work_dt;
          setValue(`man_day_am_${workDate}`, e.man_day_am.toString());
          setValue(`man_day_pm_${workDate}`, e.man_day_pm.toString());
          setValue(`man_day_night_${workDate}`, e.man_day_night.toString());
          setValue(`reason_${workDate}`, e.reason === null ? "" : e.reason);
          setValue(`rec_${workDate}`, e.rec_seq);
        });

        // 사유에 현장명 추가(멘트 넣어주실예정)
        data?.otherSiteAttendanceData?.map((e) => {
          setValue(`reason_${e.work_dt}`, `"${e.site_nm}"에 출역 존재`);
        });

        let dataArray = [];
        data?.attendanceData?.map((e) => {
          let array = {
            man_day_am: e.man_day_am.toString(),
            man_day_night: e.man_day_night.toString(),
            man_day_pm: e.man_day_pm.toString(),
            reason: e.reason === null ? "" : e.reason.toString(),
            work_dt: e.work_dt,
            rec_seq: e.rec_seq === null ? null : e.rec_seq.toString(),
          };
          dataArray.push(array);
        });
        setFormData(dataArray);
      },
      onError: (error) => {
        throw error;
      },
    });

  useEffect(() => {
    getLaborerAttendanceItem(props);
  }, []);

  // 출역 일보 출역 이력 수정 및 생성 API
  const { mutate: createLaborerAttendanceItem } = useMutation({
    mutationFn: createLaborerAttendance,
    mutationKey: ["createLaborerAttendance"],
    onSuccess: (data) => {
      if (data.done) {
        alert("출역 이력이 변경 되었습니다.");
        formFinish();
      }
    },
    onError: (error) => {
      throw error;
    },
  });

  const validateAtLeastOne = (value) => {
    const num = parseFloat(value);
    if (isNaN(num)) return true; // Allow null values
    return (num >= 0 && num <= 1) || "숫자는 0에서 1 사이여야 합니다.";
  };

  const reasonValue = (index) => {
    const morning = getValues(`man_day_am_${index}`);
    const afternoon = getValues(`man_day_pm_${index}`);
    const nighttime = getValues(`man_day_night_${index}`);
    const reason = getValues(`reason_${index}`);
    const rec = getValues(`rec_${index}`);

    if (reason === "" && morning === "" && afternoon === "" && nighttime === "")
      return true;

    if (
      getLaborerAttendanceData.otherSiteAttendanceData.some(
        (e) => e.work_dt === index
      )
    ) {
      return true;
    }
    if (morning === "" && afternoon === "" && nighttime === "") {
      return "공수를 입력해 주세요";
    }

    // 수정된 공수가 있으면서 사유가 빈값이면
    const dataFind = formData?.find((e, i) => {
      if (e.work_dt === index) {
        return e;
      }
    });

    if (dataFind && dataFind?.rec_seq !== null) {
      if (
        morning !== dataFind?.man_day_am ||
        afternoon !== dataFind?.man_day_pm ||
        nighttime !== dataFind?.man_day_night ||
        reason !== dataFind?.reason
      ) {
        if (!reason || reason?.trim() === "") {
          setValue(`reason_${index}`, "");
          setFocus(`reason_${index}`);
          return "사유를 입력해 주세요.";
        }
      }
    } else {
      if (reason?.trim().length === 0) {
        setFocus(`reason_${index}`);
        return "사유를 입력해 주세요.";
      }
    }
  };

  const onError = (errors) => {
    const errorKeys = Object.keys(errors);
    if (errorKeys.length > 0) {
      const firstError = errorKeys[0];
      setFocus(firstError);
      alert(errors[firstError]?.message || "Form validation error");
    }
  };

  const onSubmit = (data) => {
    const parsedData = Object.keys(data).reduce((acc, key) => {
      const value = data[key];
      acc[key] = value !== null && value !== "" ? value.toString() : "";
      return acc;
    }, {});

    if (JSON.stringify(modifyData(parsedData)) === JSON.stringify(formData)) {
      alert(`공수 수정 내역이 없습니다.\n공수 수정후 실행해 주세요.`);
      return;
    }

    const laborerAttendanceData = {
      site_seq: props.site_seq,
      user_seq: user_seq,
      user_site_seq: props.user_site_seq,
      attendanceList: setSubmit(modifyData(parsedData), formData),
    };

    createLaborerAttendanceItem(laborerAttendanceData);
  };

  const setSubmit = useCallback((parsedData, formData) => {
    let array = [];
    parsedData?.filter((e) => {
      const dataSome = formData?.some((se) => {
        if (se.work_dt === e.work_dt) {
          return (
            se.man_day_am === e.man_day_am &&
            se.man_day_night === e.man_day_night &&
            se.man_day_pm === e.man_day_pm &&
            se.reason === e.reason
          );
        }
      });
      if (!dataSome) {
        array.push(e);
      }
    });
    return array;
  }, []);

  const modifyData = useCallback((data) => {
    let array = [];
    selectedAllDates?.map((e, i) => {
      if (selectedAllDates <= dayjs().format("YYYY-MM-DD")) {
        const am = data[`man_day_am_${e}`];
        const pm = data[`man_day_pm_${e}`];
        const night = data[`man_day_night_${e}`];
        const reason = data[`reason_${e}`];
        const rec = data[`rec_${e}`];

        if (!!am || !!pm || !!night) {
          array.push({
            man_day_am: !am ? "0" : am,
            man_day_night: !night ? "0" : night,
            man_day_pm: !pm ? "0" : pm,
            reason: reason,
            work_dt: e,
            rec_seq: !rec ? null : rec.toString(),
          });
        }
      }
    });
    return array;
  }, []);
  return (
    <FieldGroup
      ref={formRef}
      className="new-attendance-form"
      onSubmit={handleSubmit(onSubmit, onError)}
    >
      {!getLaborerAttendanceData?.leave_dt || (
        <em
          style={{
            position: "absolute",
            top: "60px",
            left: "150px",
            fontSize: "12px",
            color: "#ff3838",
          }}
        >
          퇴사일 :{" "}
          {dayjs(getLaborerAttendanceData?.leave_dt).format("YYYY-M-D")}
        </em>
      )}
      <em
        style={{
          position: "absolute",
          top: "60px",
          left: "30px",
          fontSize: "12px",
          color: "blue",
        }}
      >
        입사일 : {dayjs(getLaborerAttendanceData?.join_dt).format("YYYY-M-D")}
      </em>
      <TableContainer>
        <TableWrapper>
          <Table id={"attendance-table"}>
            <thead>
              <tr>
                <th>출역일</th>
                <th>오전공수</th>
                <th>오후공수</th>
                <th>야간공수</th>
                <th>공수합계</th>
                <th>단가</th>
                <th width={"60%"}>사유</th>
              </tr>
            </thead>
            <tbody>
              {selectedAllDates?.map(
                (e, i) =>
                  e <= dayjs().format("YYYY-MM-DD") && (
                    <tr
                      key={i}
                      style={{
                        backgroundColor:
                          e === dayjs().format("YYYY-MM-DD") && "#FEF6D5",
                      }}
                    >
                      <WidthStyle width={"90px"}>
                        {dayjs(e).format("YYYY-M-D")}
                      </WidthStyle>
                      <WidthStyle width={"90px"}>
                        <InputBox fulled>
                          <input
                            name={`man_day_am_${e}`}
                            style={{ marginTop: 0, marginBottom: 0 }}
                            {...register(`man_day_am_${e}`, {
                              validate: {
                                atLeastOne: (value) =>
                                  validateAtLeastOne(value),
                              },
                            })}
                            onChange={(event) => {
                              numberOnly(event);
                              setValue(`man_day_am_${e}`, event.target.value);
                            }}
                            step={0.01}
                            type="text"
                            maxLength={"4"}
                            readOnly={
                              (!!getLaborerAttendanceData?.leave_dt &&
                                dayjs(e).format("YYYY-MM-DD") >
                                  dayjs(
                                    getLaborerAttendanceData?.leave_dt
                                  ).format("YYYY-MM-DD")) ||
                              dayjs(e).format("YYYY-MM-DD") <
                                dayjs(getLaborerAttendanceData?.join_dt).format(
                                  "YYYY-MM-DD"
                                ) ||
                              getLaborerAttendanceData?.otherSiteAttendanceData?.some(
                                (days) => days.work_dt === e
                              )
                            }
                          />
                        </InputBox>
                      </WidthStyle>
                      {/* <td> */}
                      <WidthStyle width={"90px"}>
                        <InputBox fulled>
                          <input
                            name={`man_day_pm_${e}`}
                            style={{ marginTop: 0, marginBottom: 0 }}
                            onWheel={(event) => event.target.blur()}
                            {...register(`man_day_pm_${e}`, {
                              validate: {
                                atLeastOne: (value) =>
                                  validateAtLeastOne(value),
                              },
                            })}
                            step={"0.01"}
                            type="text"
                            onChange={(event) => {
                              numberOnly(event);
                              setValue(`man_day_pm_${e}`, event.target.value);
                            }}
                            maxLength={"4"}
                            readOnly={
                              (!!getLaborerAttendanceData?.leave_dt &&
                                dayjs(e).format("YYYY-MM-DD") >
                                  dayjs(
                                    getLaborerAttendanceData?.leave_dt
                                  ).format("YYYY-MM-DD")) ||
                              dayjs(e).format("YYYY-MM-DD") <
                                dayjs(getLaborerAttendanceData?.join_dt).format(
                                  "YYYY-MM-DD"
                                ) ||
                              getLaborerAttendanceData?.otherSiteAttendanceData?.some(
                                (days) => days.work_dt === e
                              )
                            }
                          />
                        </InputBox>
                      </WidthStyle>
                      {/* </td> */}
                      <WidthStyle width={"90px"}>
                        <InputBox fulled>
                          <input
                            name={`man_day_night_${e}`}
                            style={{ marginTop: 0, marginBottom: 0 }}
                            onWheel={(event) => event.target.blur()}
                            {...register(`man_day_night_${e}`)}
                            step={"0.01"}
                            type="text"
                            maxLength={"4"}
                            onChange={(event) => {
                              numberOnly(event);
                              setValue(
                                `man_day_night_${e}`,
                                event.target.value
                              );
                            }}
                            readOnly={
                              (!!getLaborerAttendanceData?.leave_dt &&
                                dayjs(e).format("YYYY-MM-DD") >
                                  dayjs(
                                    getLaborerAttendanceData?.leave_dt
                                  ).format("YYYY-MM-DD")) ||
                              dayjs(e).format("YYYY-MM-DD") <
                                dayjs(getLaborerAttendanceData?.join_dt).format(
                                  "YYYY-MM-DD"
                                ) ||
                              getLaborerAttendanceData?.otherSiteAttendanceData?.some(
                                (days) => days.work_dt === e
                              )
                            }
                          />
                        </InputBox>
                      </WidthStyle>
                      <WidthStyle
                        width={"90px"}
                        style={{
                          textAlign: "center",
                          color: "#171923",
                          fontSize: "16px",
                          fontWeight: "500",
                        }}
                      >
                        {(watch(`man_day_am_${e}`) ||
                          watch(`man_day_pm_${e}`) ||
                          watch(`man_day_night_${e}`)) &&
                          (
                            Number(getValues(`man_day_am_${e}`)) +
                            Number(getValues(`man_day_pm_${e}`)) +
                            Number(getValues(`man_day_night_${e}`))
                          ).toFixed(2)}
                      </WidthStyle>
                      <WidthStyle
                        width={"90px"}
                        style={{
                          textAlign: "right",
                          color: "#171923",
                          fontSize: "16px",
                          fontWeight: "500",
                        }}
                      >
                        {getLaborerAttendanceData?.salary_amt?.toLocaleString()}
                      </WidthStyle>

                      <td>
                        <InputBox fulled>
                          <input
                            name={`reason_${e}`}
                            style={{ marginTop: 0, marginBottom: 0 }}
                            {...register(`reason_${e}`, {
                              validate: {
                                reasonValue: (value) => reasonValue(e),
                                //noSpaces: (value) => value?.trim() !== '' || '사유를 입력해 주세요.',
                              },
                            })}
                            readOnly={
                              (getLaborerAttendanceData?.leave_dt !== null &&
                                dayjs(e).format("YYYY-MM-DD") >
                                  dayjs(
                                    getLaborerAttendanceData?.leave_dt
                                  ).format("YYYY-MM-DD")) ||
                              dayjs(e).format("YYYY-MM-DD") <
                                dayjs(getLaborerAttendanceData?.join_dt).format(
                                  "YYYY-MM-DD"
                                ) ||
                              getLaborerAttendanceData?.otherSiteAttendanceData?.some(
                                (days) => days.work_dt === e
                              )
                            }
                            type="text"
                          />
                        </InputBox>
                      </td>
                    </tr>
                  )
              )}
            </tbody>
          </Table>
        </TableWrapper>
      </TableContainer>
    </FieldGroup>
  );
};
const FieldGroup = styled.form`
  //display: flex;
  //justify-content: space-between;
  //flex-wrap: wrap;
  //gap: 0 14px;
`;

const TableContainer = styled.div`
  // padding: 0 32px;
  // margin-top: 10px;
`;

const TableWrapper = styled.div`
  border: 1px solid #cbd5e0;
  border-radius: 8px;
  overflow: hidden;
  overflow-x: auto;
`;

const Table = styled.table`
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;

  thead {
    height: 38px;
  }

  th,
  td {
    text-align: left;
    //padding: 8px;
    font-size: 13px;
    font-weight: 300;
    border-right: 1px solid #cbd5e0;
    border-bottom: 1px solid #cbd5e0;
    white-space: nowrap;
  }

  th {
    background-color: #e2e8f0;
    text-align: center;
  }

  tr:last-child td {
    border-bottom: none;
  }

  td:first-of-type {
    text-align: center;
  }

  td {
    //  background: #fff;
    padding: 4px;
  }

  input {
    padding: 5px 12px;
    height: 30px;
    border: 1px solid #e6ebf0;
    color: #000000;
    font-size: 13px;
    font-weight: 400;

    :read-only {
      background-color: #dad9d9;
      color: #a12424;
    }
  }
`;

export default NewAttendanceForm;
