export const RoleType = {
  READ: 1,
  WRITE: 2,
};
Object.freeze(RoleType);

export const CompanyPlan = {
  FREE: "M0015D0001",
  BASIC: "M0015D0002",
  PRO: "M0015D0003",
};
Object.freeze(CompanyPlan);
