import styled from "@emotion/styled";
import { useCallback, useState, useEffect } from "react";
import { CompanyPlan } from "../bin/enum";
import { useRecoilState } from "recoil";
import { companyState } from "../library/recoil/atoms/companyState.js";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import axiosInstance from "../library/axios-index.js";
export default UpgradePlan;
const basicItems = [
  "근태 관리",
  "노무 관리",
  "공사 일보",
  "지출 관리",
  "외주 관리",
  "장비 관리",
  "자재 관리",
  "구인 공고 (유료)",
];
const proItems = [
  "4대보험 산출",
  "근태 관리",
  "노무 관리",
  "공사 일보",
  "지출 관리",
  "외주 관리",
  "장비 관리",
  "자재 관리",
  "구인 공고 (유료)",
];
const selectItemStyle = {
  style: { color: "#ffffff", backgroundColor: "#3279f5" },
  imageColor: "white",
  lineColor: "#E2E8F0",
  itemColor: "#fff",
  textColor: "#fff",
};
const defaultItemStyle = {
  style: { color: "#333333", backgroundColor: "#ffffff" },
  imageColor: "gray",
  lineColor: "#E2E8F0",
  itemColor: "#333",
  textColor: "#3279F5",
};
function UpgradePlan() {
  const navigate = useNavigate();
  const location = useLocation();

  const [leftItemStyle, setLeftItemStyle] = useState(selectItemStyle);
  const [rightItemStyle, setRightItemStyle] = useState(defaultItemStyle);
  // M0015D0001	무료
  // M0015D0002	베이직
  // M0015D0003	프로
  const [companyPlan, setCompanyPlan] = useState(CompanyPlan.BASIC);
  const [companyValue, setCompanyValue] = useRecoilState(companyState);
  const [items, setItems] = useState(null);
  const onClickSelectButton = useCallback(
    async (e) => {
      let plan = "";
      if (e.target.id == "leftSelect") {
        setLeftItemStyle(selectItemStyle);
        setRightItemStyle(defaultItemStyle);
        // setCompanyPlan(CompanyPlan.BASIC);
        plan = CompanyPlan.BASIC;
      } else {
        setLeftItemStyle(defaultItemStyle);
        setRightItemStyle(selectItemStyle);
        // setCompanyPlan(CompanyPlan.PRO);
        plan = CompanyPlan.PRO;
      }

      if (
        companyValue?.stamp_img !== "" &&
        companyValue?.biz_reg_img !== "" &&
        companyValue?.corp_nm !== "" &&
        companyValue?.ceo_nm !== "" &&
        companyValue?.corp_reg_num !== "" &&
        companyValue?.biz_reg_num !== "" &&
        companyValue?.biz_type !== "" &&
        companyValue?.biz_sector !== "" &&
        companyValue?.addr1 !== "" &&
        companyValue?.manager_nm !== "" &&
        companyValue?.email_addr !== "" &&
        companyValue?.manager_phone_num !== ""
      ) {
        // 정보가 았울때
        try {
          const result = await fetchModify(companyValue, plan);
          if (result.code === 200) {
            setCompanyValue((prevState) => ({
              ...prevState,
              plan_id: plan,
            }));

            navigate("/welcome", {
              state: {
                plan: plan,
              },
              replace: true,
            });
            setCompanyPlan(plan);
          }
        } catch (e) {
          alert(e);
          throw e;
        }
      } else {
        // 정보가 없을때
        navigate("/company-info", {
          state: {
            plan: plan,
          },
          replace: true,
        });
        setCompanyPlan(plan);
      }
    },
    [leftItemStyle, rightItemStyle, companyPlan]
  );

  // 회사 정보 수정
  const fetchModify = async (formData, companyPlan) => {
    const token = localStorage.getItem("admin_token");
    const { data } = await axiosInstance.post(`/company/modifyInfo`, {
      lang: "ko",
      uuid: "string",
      token,
      ...formData,
      corp_seq: formData.corp_seq,
      // biz_reg_num:
      //   formData.biz_reg_num1 + formData.biz_reg_num2 + formData.biz_reg_num3,
      // corp_reg_num: formData.corp_reg_num1 + formData.corp_reg_num2,
      plan_id: companyPlan,
    });

    return data;
  };
  useEffect(() => {
    window.addEventListener("keydown", (event) => {
      if (
        event.keyCode === 123 ||
        (event.ctrlKey && event.shiftKey && event.keyCode === 73)
      ) {
        event.preventDefault();
      }
    });

    document.oncontextmenu = function () {
      return false;
    };
  }, []);
  return (
    <Container>
      <Header style={{ marginTop: "50px" }}>플랜을 업그레이드 하세요</Header>
      <Header style={{ flexDirection: "column" }}>
        {location.state.isFree && (
          <span>무료 플랜(60일)이 만료되었습니다.</span>
        )}
        <span>
          플랜을 <span style={{ fontWeight: "700" }}>업그레이드</span>
          하시면 제한 없이 서비스를 사용하실 수 있습니다.
        </span>
      </Header>
      <div style={{ display: "flex" }}>
        {location.state.isFree && (
          <Box style={{ marginRight: "30px", ...leftItemStyle.style }}>
            <BoxTitle>BASIC</BoxTitle>
            <h2>베이직</h2>
            <div
              style={{
                height: "1px",
                width: "160px",
                margin: "10px",
                backgroundColor: leftItemStyle.lineColor,
              }}
            />
            <div style={{ display: "flex" }}>
              <h1 style={{ marginBottom: "5px" }}>2,900원</h1>
              <div
                style={{
                  fontSize: "12px",
                  marginTop: "23px",
                  marginLeft: "5px",
                }}
              >
                {" "}
                / 월
              </div>
            </div>
            <h5 style={{ marginBottom: "20px" }}>근로자당 (부가세 별도)</h5>

            <div
              style={{
                height: "1px",
                width: "160px",
                margin: "10px",
                backgroundColor: leftItemStyle.lineColor,
              }}
            />

            <List>
              {basicItems.map((item, index) => (
                <Item key={index} style={{ color: leftItemStyle.itemColor }}>
                  <Checkmark
                    src={`/assets/icons/check-${leftItemStyle.imageColor}.svg`}
                  />
                  {item}
                </Item>
              ))}
            </List>

            <BoxButton
              id={"leftSelect"}
              onClick={(e) => onClickSelectButton(e)}
              style={{ marginTop: "33px" }}
            >
              선 택
            </BoxButton>
            <h5 style={{ marginTop: "20px" }}>최소 인원 5명</h5>
          </Box>
        )}
        <Box style={{ ...rightItemStyle.style }}>
          <BoxTitle>Pro</BoxTitle>
          <h2>프로</h2>

          <div
            style={{
              height: "1px",
              width: "160px",
              margin: "10px",
              backgroundColor: rightItemStyle.lineColor,
            }}
          />
          <div style={{ display: "flex" }}>
            <h1 style={{ marginBottom: "5px" }}>3,900원</h1>
            <div
              style={{ fontSize: "12px", marginTop: "23px", marginLeft: "5px" }}
            >
              {" "}
              / 월
            </div>
          </div>
          <h5 style={{ marginBottom: "20px" }}>근로자당 (부가세 별도)</h5>

          <div
            style={{
              height: "1px",
              width: "160px",
              margin: "10px",
              backgroundColor: rightItemStyle.lineColor,
            }}
          />

          <List>
            {proItems.map((item, index) => (
              <Item
                key={index}
                style={
                  item === "4대보험 산출"
                    ? { gridColumn: "1/3", color: "red", fontWeight: "bold" }
                    : { color: rightItemStyle.itemColor }
                }
              >
                <Checkmark
                  src={`/assets/icons/check-${rightItemStyle.imageColor}.svg`}
                />
                {item}
              </Item>
            ))}
          </List>

          <BoxButton id={"rightSelect"} onClick={(e) => onClickSelectButton(e)}>
            선 택
          </BoxButton>
          <h5 style={{ marginTop: "20px" }}>최소 인원 5명</h5>
        </Box>
      </div>

      {/*footer  */}

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          color: "#818791",
          fontSize: "14px",
          marginTop: "50px",
        }}
      >
        <Checkmark src="/assets/icons/alert-circle-gray.svg" />
        VAT 제외 금액이며, 회사 단위로 반영됩니다.
      </div>
      {location.state.isFree || (
        <BoxButton
          id={"rightSelect"}
          style={{ marginTop: "30px" }}
          onClick={(e) => {
            e.preventDefault();
            navigate(-2);
          }}
        >
          취소
        </BoxButton>
      )}
    </Container>
  );
}

const Padding = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 6px;
  padding: 16px 24px;

  & > div {
    width: 33.3%;
    &:nth-of-type(2) {
      text-align: center;
    }
  }
`;

const Content = styled.div`
  height: 100%;
  overflow-y: auto;
  padding: 0 24px;

  -ms-overflow-style: none;
  -moz-overflow-style: none;
  -webkit-overflow-style: none;
  -o-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
    width: 0 !important;
  }
`;

const Container = styled.div`
  background: #f7fafc;
  height: 100vh;
  justify-content: center;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
`;

const SidebarButton = styled.img`
  cursor: pointer;
`;

export const BoxTitle = styled.div`
  width: 88px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ebf2fe;
  color: #3279f5;
  font-size: 14px;
  font-weight: 400;
  border-radius: 10px;
  margin-top: 20px;

  &.white-text {
    color: #3279f5;
    background-color: #ffffff;
  }
`;

export const BoxButton = styled.div`
  width: 160px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  margin-top: 20px;
  cursor: pointer;

  color: #3279f5;
  font-size: 16px;
  font-weight: 500;
  margin-top: 10px;

  border-radius: 4px;
  border-style: solid;
  border-color: #3279f5;
  border-width: 1px;
`;

export const Box = styled.div`
  width: 200px;
  height: 435px;
  opacity: 100%;
  color: #333333;
  background-color: #ffffff;
  border-radius: 16px;
  margin-top: 15px;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    font-size: 26px;
    font-weight: 700;
    margin-top: 10px;
  }

  h2 {
    margintop: 20px;
    margin-bottom: 4px;
    font-size: 20px;
    font-weight: 500;
  }

  h4 {
    height: 20px;
    font-size: 11px;
    font-weight: 400;
    margin: 0;
    //color: #4a5568;
  }

  h5 {
    font-size: 10px;
    font-weight: 400;
    margin: 0;
    //color: #4a5568;
  }
`;

export const List = styled.ul`
  display: grid;
  grid-template-columns: repeat(2, auto);
  gap: 10px;
  list-style-type: none;
  padding: 0;
`;

export const Item = styled.li`
  display: flex;
  align-items: center;
  font-size: 10px;
  color: #333; /* 검정색 텍스트 */

  &.white-text {
    color: #fff; /* 흰색 텍스트 */
  }
`;

const Checkmark = styled.img`
  width: 14px;
  margin-right: 5px;
`;
