import Cookies from "js-cookie";
import axios, { HttpStatusCode } from "axios";
import { HOST_URL } from "../bin/env";
import { REACT_APP_API_KEY } from "../bin/env";
let isAlertShown = false;
// export const baseUrl = HOST_URL;
const domain = process.env.NEXT_PUBLIC_DOMAIN;
const axiosInstance = axios.create({
  baseURL: HOST_URL,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${localStorage.getItem("admin_token")}`,
    "X-API-KEY": REACT_APP_API_KEY,
  },
});

const gotoLogin = () => {
  Cookies.remove("accessToken", {
    path: "/",
  });
  Cookies.remove("refreshToken", {
    path: "/",
  });
  Cookies.remove("accessToken", {
    domain: domain,
    path: "/",
  });
  Cookies.remove("refreshToken", {
    domain: domain,
    path: "/",
  });
  // Cookies.remove('refreshToken');
  window.location.href = "/login";
};

// 요청 인터셉터 추가
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("admin_token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 응답 인터셉터 추가
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status) {
      if (error.response.status === HttpStatusCode.TooManyRequests) {
        if (!isAlertShown) {
          // 알림이 한번 호출
          isAlertShown = true;
          alert(
            "현재 요청이 많아 처리가 지연되고 있습니다. 잠시 후 다시 시도해 주세요."
          );
          setTimeout(() => {
            isAlertShown = false;
          }, 3000);
        }
        return new Promise(() => {});
      }
      return Promise.reject(error);
    }
  }
);

export default axiosInstance;
