import React, { useCallback, useEffect, useRef, useState } from "react";
import AxiosNavigate from "../../library/axios-navigate";
import { Header } from "../../compontents/Header";
import styled from "@emotion/styled";
import { PagingFooter } from "../../compontents/PagingFooter";
import Pagination from "react-js-pagination";
import PagesList from "../../compontents/PagesList";
import moment from "moment";
import Modal from "../../compontents/Modal";
import DiscountRuleForm from "./discount-rule-form";
import SideModal from "../../compontents/SideModal";
import { Content } from "../../library/styled-components";
import { ModalButton } from "../../compontents/ModalButton";
import { useMutation } from "@tanstack/react-query";
import {
  deleteDiscountRule,
  getDiscountRule,
} from "../../library/admin-react-query";
import { addCommas, BlockScrolling } from "../../bin/common";
import { TableStyle } from "../../library/styled-components";
const options = [
  { value: 20, label: "20" },
  { value: 30, label: "30" },
  { value: 50, label: "50" },
  { value: 100, label: "100" },
];
const discountType = [
  {
    value: "FR",
    label: "정률(%)",
  },
  { value: "FA", label: "정액(원)" },
  { value: "FF", label: "고정액(원)" },
];

const DiscountRuleAdmin = () => {
  const formRef = useRef();
  const closeModal = useRef(false);

  const [totalCount, setTotalCount] = useState(0);
  const [limit, setLimit] = useState(options[0].value);
  const [page, setPage] = useState(1);
  const [selectInfo, setSelectInfo] = useState({});

  const [openedPolicyForm, setOpenedPolicyForm] = useState(false);
  const [openedDetail, setOpenedDetail] = useState(false);

  BlockScrolling([openedDetail, openedPolicyForm]);

  // 할인 정책 정보
  const { mutate: getDiscountRuleMutate, data: getDiscountRuleData } =
    useMutation({
      mutationKey: ["getDiscountRule"],
      mutationFn: getDiscountRule,
      onSuccess: (data) => {
        setTotalCount(data.totalCount);
        if (openedDetail) {
          setSelectInfo(
            data.list.find((e) => e.dscnt_seq === selectInfo.dscnt_seq)
          );
        }
      },
      onError: (error) => {
        alert(error.message);
      },
    });

  // 할인 정책 삭제
  const { mutate: daleteDiscountRuleMutate } = useMutation({
    mutationKey: ["deleteDiscountRule"],
    mutationFn: deleteDiscountRule,
    onSuccess: (data) => {
      if (data.done) {
        handleLoad();
        setOpenedDetail(false);
      }
    },
    onError: (error) => {
      console.error(error.message);
    },
  });

  // 할인 정책 삭제
  const handleSelectedDelete = (faq_seq) => {
    const result = window.confirm("정말로 삭제하겠습니까?");
    if (result) {
      daleteDiscountRuleMutate(selectInfo.dscnt_seq);
    }
  };

  // 할인 정책 수정
  const handleSelectedModify = () => {
    setOpenedPolicyForm(true);
  };
  const handleLoad = useCallback(() => {
    getDiscountRuleMutate({
      page: page,
      limit: limit,
    });
  }, [page, limit]);

  useEffect(() => {
    handleLoad();
  }, [handleLoad]);

  const handleFinishAction = (value) => {
    if (value === "create") {
      setOpenedPolicyForm(false);
      setPage(1);
      setLimit(20);
    } else if (value === "update") {
      setOpenedPolicyForm(false);
    }
    handleLoad();
  };
  return (
    <AxiosNavigate>
      <div>
        <Header>
          <div>할인 정책</div>
          <Button
            style={{ backgroundColor: "#3279F5", color: "#fff" }}
            onClick={(event) => {
              event.preventDefault();
              setOpenedPolicyForm(true);
            }}
          >
            작성하기
          </Button>
        </Header>

        <TableStyle>
          <TableWrapper>
            <table>
              <thead>
                <tr>
                  <th width={"70px"}>구분</th>
                  <th>제목</th>
                  <th width={"70px"}>할인구분</th>
                  <th width={"150px"}>할인율/금액</th>
                  <th width={"150px"}>기간</th>
                  <th width={"150px"}>사용여부</th>
                  <th width={"150px"}>등록일</th>
                </tr>
              </thead>
              <tbody>
                {getDiscountRuleData &&
                  getDiscountRuleData?.list?.map((e, i) => (
                    <tr key={i}>
                      <td>{i + limit * (page - 1) + 1}</td>

                      <td className={"table-left"}>
                        <div
                          style={{
                            // width: '50%',
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          {e.dscnt_title}
                          <Button
                            onClick={() => {
                              // handleSelectedUserInfo(e.user_seq);
                              setSelectInfo(e);
                              setOpenedDetail(true);
                            }}
                            style={{ marginLeft: "8px" }}
                          >
                            상세보기
                          </Button>
                        </div>
                      </td>
                      <td>
                        {
                          discountType?.find(
                            (item) => item.value === e.dscnt_type
                          )?.label
                        }
                      </td>
                      <td>
                        {e.dscnt_type === "FR"
                          ? e.dscnt_amt + "%"
                          : addCommas(e.dscnt_amt) + "원"}
                      </td>
                      <td>
                        {e.date_type === "M"
                          ? e.period + "개월"
                          : e.period + "년"}
                      </td>
                      <td>{e.use_yn === "Y" ? "사용" : "중지"}</td>
                      <td>
                        {moment(e.crt_dt?.split("T")[0]).format(
                          "YYYY년 M월 D일"
                        )}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </TableWrapper>
        </TableStyle>

        <PagingFooter>
          <Pagination
            prevPageText={<Icon src="/assets/icons/chevron-left.svg" />}
            nextPageText={<Icon src="/assets/icons/chevron-right.svg" />}
            activePage={page}
            itemsCountPerPage={limit}
            totalItemsCount={totalCount === 0 ? 1 : totalCount}
            pageRangeDisplayed={5}
            hideFirstLastPages={true}
            onChange={(e) => setPage(e)}
          />
          <PagesList
            options={options}
            onChange={(e) => {
              setLimit(e.value);
              setPage(1);
            }}
          ></PagesList>
        </PagingFooter>

        <Modal
          opend={openedPolicyForm}
          closeModal={() => {
            setOpenedPolicyForm(closeModal.current);
            closeModal.current = false;
          }}
          header={`할인 정책 ${openedDetail ? "수정" : "등록"}`}
          formRef={formRef}
          cancelText={"취소"}
          okText={"확인"}
          widthCheck={"950px"}
          heightCheck={"950px"}
        >
          <DiscountRuleForm
            ref={formRef}
            data={openedDetail ? selectInfo : null}
            action={handleFinishAction}
          ></DiscountRuleForm>
        </Modal>

        <SideModal
          opend={openedDetail}
          closeModal={() => setOpenedDetail(false)}
          header={"할인 정책 상세"}
        >
          {selectInfo && (
            <Side>
              <Content>
                <figure>
                  <h6>제목</h6>
                  <h5>{selectInfo?.dscnt_title}</h5>
                </figure>
                <figure>
                  <h6>할인구분</h6>
                  <h5>
                    {
                      discountType?.find(
                        (item) => item.value === selectInfo?.dscnt_type
                      )?.label
                    }
                  </h5>
                </figure>
                <figure>
                  <h6>할인율/금액</h6>
                  <h5>
                    {selectInfo.dscnt_type === "FR"
                      ? selectInfo.dscnt_amt + "%"
                      : addCommas(selectInfo.dscnt_amt) + "원"}
                  </h5>
                </figure>
                <figure>
                  <h6>기간</h6>
                  <h5>
                    {selectInfo.date_type === "M"
                      ? selectInfo.period + "개월"
                      : selectInfo.period + "년"}
                  </h5>
                </figure>
                <figure>
                  <h6>사용여부</h6>
                  <h5>{selectInfo?.use_yn === "Y" ? "사용" : "중지"}</h5>
                </figure>
                <figure>
                  <h6>등록일</h6>
                  <h5>
                    {moment(selectInfo?.crt_dt?.split("T")[0]).format(
                      "YYYY년 M월 D일"
                    )}
                  </h5>
                </figure>
                <figure style={{ alignItems: "stretch" }}>
                  <h6>설명</h6>
                  <h5>{selectInfo.dscnt_desc}</h5>
                </figure>
              </Content>

              <div style={{ display: "grid" }} className={"grid"}>
                <ModalButton onClick={() => handleSelectedDelete()}>
                  삭제
                </ModalButton>
                <ModalButton onClick={() => handleSelectedModify()}>
                  수정
                </ModalButton>
              </div>
            </Side>
          )}
        </SideModal>
      </div>
    </AxiosNavigate>
  );
};
const Side = styled.div`
  //height: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;

  .grid {
    grid-template-columns: repeat(2, 100px);
    padding-bottom: 20px;
    margin-left: auto;
  }

  > div > figure {
    h6 {
      min-width: 80px;
    }

    a {
      text-decoration: none;
      color: #3279f5;
    }

    h5 p {
      margin: 0;
    }
  }

  > div > figure:last-child {
    div {
      display: grid;
      gap: 5px;
      grid-template-columns: repeat(1, 1fr);
    }

    h5 {
      cursor: pointer;
    }
  }

  > div > figure:nth-of-type(3) {
    h5 {
      width: calc(100% - 80px);

      img {
        width: auto;
        max-width: 100%;
      }
    }
  }

  figure:nth-of-type(3) {
    * {
      width: auto !important;
      max-width: 100% !important;
    }

    table {
      width: 100% !important;
    }
  }
`;

const Icon = styled.img`
  width: 16px;
`;
const Button = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: fit-content;
  min-height: 32px;
  padding: 0 9px;
  justify-content: center;
  align-items: center;
  gap: 9px;
  border-radius: 6px;
  border: 1.125px solid #e2e8f0;
  background: #fff;
  box-sizing: border-box;
  color: #4a5568;
  leading-trim: both;
  text-edge: cap;
  font-size: 14px;
  cursor: pointer;

  //    margin-top: 5px;
`;
const TableContainer = styled.div`
  padding: 0 32px;
  margin-top: 28px;
`;

const TableWrapper = styled.div`
  /* border: 1px solid #CBD5E0;
    border-radius: 8px;
    overflow: hidden;
    overflow-x: auto; */
  th,
  td {
    text-align: center;
  }
`;

const Table = styled.table`
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;

  th,
  td {
    text-align: center;
    padding: 8px;
    font-size: 13px;
    font-weight: 300;
    border-right: 1px solid #cbd5e0;
    border-bottom: 1px solid #cbd5e0;
    white-space: nowrap;
  }

  th {
    background-color: #f7fafc;
  }

  tr:last-child td {
    border-bottom: none;
  }
`;
export default DiscountRuleAdmin;
