import styled from "@emotion/styled";
import dayjs from "dayjs";
import React, { forwardRef } from "react";
import { addCommas, idNumReveal } from "../../bin/common";
import { TablePrintStyle } from "../../library/styled-components";
import { useEffect, useState } from "react";
import CustomChecked from "../CustomChecked";
import { findByText } from "@testing-library/react";

const LaborEmployPagePrint = forwardRef((props, ref) => {
  const employees = props.data.employees;
  const jobPositon = props.data.jobPositon;
  const position = props.data.position;
  const rank = props.data.rank;
  const nations = props.data.nations;
  const company = props.data.company;

  const [check, setCheck] = useState();
  return (
    <Container ref={ref} className="print-landscape">
      <Header>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Title style={{ display: "flex", columnGap: "35px" }}>
            근로자 정보
            <div className="checked">
              <CustomChecked
                style={{ paddingLeft: "0px" }}
                onChange={(checked) => {
                  setCheck(checked);
                }}
                data={{
                  text: "주민등록번호(뒷부분 6자리) 공개",
                  isCheck: check,
                }}
              />
            </div>
          </Title>
          <Text>
            <strong>회사명</strong> <span>{company}</span>
          </Text>
        </div>

        <div></div>
      </Header>

      <TablePrintStyle>
        <div className="table_wrapper">
          <table>
            <thead>
              <tr>
                <th rowSpan={2} style={{ textAlign: "center" }} width={"30px"}>
                  구분
                </th>
                <th rowSpan={2} style={{ textAlign: "center", width: "100px" }}>
                  이름
                </th>
                <th rowSpan={2} style={{ textAlign: "center", width: "70px" }}>
                  국적
                </th>
                <th rowSpan={2} style={{ textAlign: "center", width: "90px" }}>
                  주민등록번호
                  <br />
                  (외국인관리번호)
                </th>
                <th rowSpan={2} style={{ textAlign: "center" }}>
                  나이
                </th>
                <th rowSpan={2} style={{ textAlign: "center" }}>
                  현장 명
                </th>
                <th rowSpan={2} style={{ textAlign: "center" }}>
                  직종
                </th>
                <th rowSpan={2} style={{ textAlign: "center" }}>
                  역할
                </th>
                <th rowSpan={2} style={{ textAlign: "center" }}>
                  직급
                </th>
                <th rowSpan={2} style={{ textAlign: "center" }}>
                  소속
                </th>
                <th rowSpan={2} style={{ textAlign: "center", width: "90px" }}>
                  입사일
                </th>
                <th rowSpan={2} style={{ textAlign: "center", width: "90px" }}>
                  퇴사일
                </th>
                <th colSpan={3} style={{ textAlign: "center" }}>
                  급여
                </th>
              </tr>
              <tr>
                <th style={{ textAlign: "center", width: "55px" }}>구분</th>
                <th style={{ textAlign: "center", width: "70px" }}>임금</th>
              </tr>
            </thead>
            <tbody>
              {employees.map((e, i) => (
                <tr
                  key={i}
                  className={employees.length === i + 1 ? "none" : ""}
                >
                  <td>{i + 1}</td>
                  <td>
                    <div
                      style={{
                        width: "100px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        whiteSpace: "break-spaces",
                      }}
                    >
                      &nbsp;{e.user_nm}
                    </div>
                  </td>
                  <td style={{ textAlign: "left", minWidth: "70px" }}>
                    {nations[e.nationality]}
                  </td>
                  <td style={{ minWidth: "90px" }}>
                    {idNumReveal(e.id_num, check)}
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {e.birth_day
                      ? +dayjs().format("YYYY") -
                        +dayjs(e.birth_day).format("YYYY")
                      : 0}
                    세
                  </td>
                  <td
                    style={{
                      textAlign: "left",
                      whiteSpace: "break-spaces",
                      minWidth: "200px",
                    }}
                  >
                    {e.site_nm}
                  </td>
                  <td style={{ textAlign: "left" }}>
                    {jobPositon[e.job_position]}
                  </td>
                  <td style={{ textAlign: "left" }}>{position[e.role_nm]}</td>
                  <td style={{ textAlign: "left" }}>{rank[e.rank_nm]}</td>
                  <td style={{ textAlign: "left" }}>{e.class_nm}</td>
                  <td
                    style={{
                      textAlign: "center",
                      minWidth: "90px",
                    }}
                  >
                    {e.join_dt ? dayjs(e.join_dt).format("YYYY년 M월 D일") : ""}
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      minWidth: "90px",
                    }}
                  >
                    {e.leave_dt
                      ? dayjs(e.leave_dt).format("YYYY년 M월 D일")
                      : ""}
                  </td>
                  <td style={{ minWidth: "55px" }}>
                    {e.salary_type === "D"
                      ? "일급"
                      : e.salary_type === "M"
                      ? "월급"
                      : "본사 협의"}
                  </td>
                  <td className={"table-right"} style={{ minWidth: "70px" }}>
                    {addCommas(e.salary_amt)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </TablePrintStyle>
    </Container>
  );
});

const Container = styled.div`
  //border: 1px solid #E2E8F0;
  // border: 1px solid #E2E8F0;

  background: #fff;
  padding: 20px 24px;

  @page {
    size: A4 landscape !important;
    margin-top: 20px;
    margin-bottom: 25px;
  }
  @media print {
    table th {
      background-color: #edf2f7 !important;
      -webkit-print-color-adjust: exact;
    }
    .checked {
      display: none;
    }
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

const Title = styled.div`
  color: #171923;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  margin-bottom: 6px;
`;

const HeaderGroup = styled.div`
  display: flex;
`;

const Badge = styled.div`
  display: flex;
  padding: 4px 6px;
  align-items: flex-start;
  border-radius: 6px;
  background: #f7fafc;

  &:not(:last-child) {
    margin-right: 4px;
  }
`;

const Label = styled.div`
  color: #718096;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 8px;
  font-style: normal;
  font-weight: 500;
  line-height: 8px;
  margin-right: 4px;
`;

const Text = styled.div`
  color: #171923;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 8px;
  font-style: normal;
  font-weight: 500;
  line-height: 8px;
`;

export default LaborEmployPagePrint;
