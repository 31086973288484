import styled from "@emotion/styled";
import React, { forwardRef, useEffect, useState } from "react";
import dayjs from "dayjs";
import { addCommas, idNumReveal } from "../../bin/common";
import { TablePrintStyle } from "../../library/styled-components";
import CustomChecked from "../CustomChecked";
import PrintSignComponent from "../PrintSignComponent";
const LaborAttendancePagePrint = forwardRef((props, ref) => {
  const data = props.data;
  const totalCount = props.totalCount;
  const siteUserCount = props.siteUserCount;
  const attendanceList = data.attendances;

  const [check, setCheck] = useState();

  return (
    <Container ref={ref} className="print-landscape">
      <Header>
        <div
          style={{
            width: "80%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Title>
            출역 일보
            <div className="checked">
              <CustomChecked
                style={{ paddingLeft: "0px" }}
                onChange={(checked) => {
                  setCheck(checked);
                }}
                data={{
                  text: "주민등록번호(뒷부분 6자리) 공개",
                  isCheck: check,
                }}
              />
            </div>
          </Title>
          <Text></Text>
        </div>

        <div></div>
        <div>
          <PrintSignComponent seq={data.site_seq}></PrintSignComponent>
          <div
            style={{
              marginTop: "10px",
              color: "#4a5568",
              fontSize: "13px",
              textAlign: "right",
            }}
          >
            출역인원 : {totalCount} / 전체인원 : {siteUserCount}
          </div>
        </div>
      </Header>
      <TablePrintStyle>
        <div className="table_wrapper">
          <table>
            <thead style={{ height: "unset" }}>
              <tr>
                <th rowSpan={2}>구분</th>
                <th rowSpan={2}>이름</th>
                <th width={"145px"} rowSpan={2}>
                  주민등록번호
                </th>
                <th rowSpan={2}>직종</th>
                <th rowSpan={2}>소속</th>
                <th rowSpan={2}>출근시간</th>
                <th rowSpan={2}>퇴근시간</th>
                <th rowSpan={2}>
                  실제
                  <br />
                  출근시간
                </th>
                <th rowSpan={2}>
                  실제
                  <br />
                  퇴근시간
                </th>
                <th colSpan={4}>출역공수</th>
                <th rowSpan={2}>단가</th>
                <th rowSpan={2}>금액</th>
                <th rowSpan={2}>현장</th>
              </tr>
              <tr>
                <th>오전</th>
                <th>오후</th>
                <th>야간</th>
                <th className={"print_border"}>합계</th>
              </tr>
            </thead>
            <tbody>
              {attendanceList?.map((e, i) => (
                <tr
                  key={i}
                  className={attendanceList.length === i + 1 && "none"}
                >
                  <td>
                    <div>{i + 1}</div>
                  </td>
                  <td style={{ textAlign: "left" }}>{e.user_nm}</td>
                  <td>{idNumReveal(e.id_num, check)}</td>
                  <td>{e.job_position}</td>
                  <td style={{ textAlign: "left" }}>{e.cmpny_nm}</td>
                  <td>
                    {e.start_dt
                      ? dayjs(e.start_dt).add(-9, "hour").format("HH:mm")
                      : ""}
                  </td>
                  <td>
                    {e.end_dt
                      ? dayjs(e.end_dt).add(-9, "hour").format("HH:mm")
                      : ""}
                  </td>
                  <td>
                    {e.actual_start_dt
                      ? dayjs(e.actual_start_dt).add(-9, "hour").format("HH:mm")
                      : ""}
                  </td>
                  <td>
                    {e.actual_end_dt
                      ? dayjs(e.actual_end_dt).add(-9, "hour").format("HH:mm")
                      : ""}
                  </td>
                  <td>{e.man_day_am}</td>
                  <td>{e.man_day_pm}</td>
                  <td>{e.man_day_night}</td>
                  <td>{e.man_day}</td>
                  <td style={{ textAlign: "right" }}>{addCommas(e.day_amt)}</td>
                  <td style={{ textAlign: "right" }}>
                    {addCommas(e.salary_amt)}
                  </td>
                  <td style={{ textAlign: "left" }}>{e.site_nm}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </TablePrintStyle>
    </Container>
  );
});

const Container = styled.div`
  //border: 1px solid #E2E8F0;
  background: #fff;
  padding: 20px 24px;

  @page {
    size: A4 landscape !important;
    margin-top: 20px;
    margin-bottom: 25px;
  }
  @media print {
    table th {
      background-color: #edf2f7 !important;
      -webkit-print-color-adjust: exact;
    }
    .checked {
      display: none;
    }
    .table-wrapper {
      break-after: page;
    }

    body {
      margin: 0;
    }
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

const Title = styled.div`
  color: #171923;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  margin-bottom: 6px;
  display: flex;
  column-gap: 35px;
`;

const HeaderGroup = styled.div`
  display: flex;
`;

const Badge = styled.div`
  display: flex;
  padding: 4px 6px;
  align-items: flex-start;
  border-radius: 6px;
  background: #f7fafc;

  &:not(:last-child) {
    margin-right: 4px;
  }
`;

const Label = styled.div`
  color: #718096;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 8px;
  font-style: normal;
  font-weight: 500;
  line-height: 8px;
  margin-right: 4px;
`;

const Text = styled.div`
  color: #171923;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 8px;
  font-style: normal;
  font-weight: 500;
  line-height: 8px;
`;

export default LaborAttendancePagePrint;
