import styled from "@emotion/styled";

// export const ModalButton = styled.div`
//   display: flex;
//   height: 24px;
//   padding: 6px 12px;
//   justify-content: center;
//   align-items: center;
//   gap: 6px;
//   border-radius: 6px;
//   background: #edf2f7;
//   cursor: pointer;
//   color: #1a202c;
//   font-size: 14px;
//   font-style: normal;
//   font-weight: 600;
//   line-height: 16px;
//   margin-left: 17px;
//   white-space: nowrap;
//   width: auto;
//   img {
//     width: 14px;
//   }
// `;

export const ModalButton = styled.button`
  display: flex;
  height: 36px;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 6px;
  background: #edf2f7;
  cursor: pointer;
  color: #1a202c;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  margin-left: 17px;
  white-space: nowrap;
  width: auto;
  border: none; /* 기존 div에는 border가 없으므로 추가 */
  outline: none; /* 포커스 효과 제거 */

  &:hover {
    background: #e2e8f0;
  }

  img {
    width: 14px;
  }
`;
