import React, { useEffect, useRef, useState } from "react";
import AxiosNavigate from "../../library/axios-navigate";
import { Header } from "../../compontents/Header";
import { PagingFooter } from "../../compontents/PagingFooter";
import Pagination from "react-js-pagination";
import PagesList from "../../compontents/PagesList";
import SideModal from "../../compontents/SideModal";
import Modal from "../../compontents/Modal";
import styled from "@emotion/styled";
import moment from "moment";
import ButtonComponent from "../../compontents/Button";
import { useMutation } from "@tanstack/react-query";
import { planData } from "../../library/axios-query";
import {
  createPopupAdmin,
  deletePopupAdmin,
  getPopupListAdmin,
  updatePopupAdmin,
} from "../../library/admin-react-query";
import { FormProvider, useForm } from "react-hook-form";
import NewNoticeForm from "./new-notice-form";
import { BlockScrolling, dataFilter } from "../../bin/common";
import { Content } from "../../library/styled-components";
import { ModalButton } from "../../compontents/ModalButton";
import ModifyNoticeForm from "./modify-notice-form";
import SearchBox from "../../compontents/Search";
import EmptyContainer from "../../compontents/EmptyContainer";
import { TableStyle } from "../../library/styled-components";
const checkDefault = {
  company: false,
  worker: false,
};

const NoticeAdmin = () => {
  const methods = useForm();

  const [selectedNotice, setSelectedNotice] = useState({});
  const formRef = useRef(null);
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  const [typeNames, setTypeNames] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [limit, setLimit] = useState(0);
  const [page, setPage] = useState(0);
  const [searchTxt, setSearchTxt] = useState(""); // 엔터
  const [searchValue, setSearchValue] = useState(""); // 키보드

  const [openedDetailNotice, setOpenedDetailNotice] = useState(false);
  const [openedNewNotice, setOpenedNewNotice] = useState(false);
  const [openedModifyNotice, setOpenedModifyNotice] = useState(false);
  const [firstRendering, setFirstRendering] = useState(true);

  const closeModal = useRef(false);
  BlockScrolling([openedDetailNotice, openedNewNotice]);
  // 플랜 정보
  const { mutate: planDataItem, data: planDataList } = useMutation({
    mutationKey: ["planData"],
    mutationFn: planData,
    onSuccess: (data) => {
      if (!typeNames) return;
      const typeName = data.map((e) => {
        return { value: e.dtl_cd_nm, label: e.dtl_cd_nm };
      });
      setTypeNames(typeName);
    },
    onError: (error) => {
      throw error;
    },
  });

  // admin 팝업 정보 조회
  const { mutate: getPopupListAdminItem, data: getPopupListAdminData } =
    useMutation({
      mutationKey: ["getPopupListAdmin"],
      mutationFn: getPopupListAdmin,
      onSuccess: (data) => {
        if (firstRendering) {
          setLimit(20);
          setPage(1);
          setFirstRendering(false);
        }
        setTotalCount(data.total);
      },

      onError: (error) => {
        throw error;
      },
    });
  // 팝업 정보 생성
  const { mutate: createPopupAdminItem } = useMutation({
    mutationKey: ["createPopupAdmin"],
    mutationFn: createPopupAdmin,
    onSuccess: (data) => {
      if (data.done === true) {
        setPage(1);
        setLimit(20);
        setSearchTxt("");
        const listItems = {
          page: page,
          limit: limit,
          search_txt: searchTxt,
        };
        getPopupListAdminItem(listItems);
        setOpenedNewNotice(false);
      }
    },

    onError: (error) => {
      throw error;
    },
  });

  // 팝업 정보 삭제
  const { mutate: deletePopupAdminItem } = useMutation({
    mutationKey: ["deletePopupAdmin"],
    mutationFn: deletePopupAdmin,
    onSuccess: (data) => {
      if (data.done === true) {
        setPage(1);
        setLimit(20);
        setSearchTxt("");
        const listItems = {
          page: page,
          limit: limit,
          search_txt: searchTxt,
        };
        getPopupListAdminItem(listItems);
        setOpenedDetailNotice(false);
      }
    },

    onError: (error) => {
      throw error;
    },
  });

  // 팝업 정보 수정
  const { mutate: updatePopupAdminItem } = useMutation({
    mutationKey: ["updatePopupAdmin"],
    mutationFn: updatePopupAdmin,
    onSuccess: (data) => {
      if (data.done === true) {
        setPage(1);
        setLimit(20);
        setSearchTxt("");
        const listItems = {
          page: page,
          limit: limit,
          search_txt: searchTxt,
        };
        getPopupListAdminItem(listItems);
        setOpenedModifyNotice(false);
        // setOpenedDetailNotice(false);
      }
    },

    onError: (error) => {
      throw error;
    },
  });

  const handleSelectedDelete = (pop_seq) => {
    const result = window.confirm("정말로 삭제하겠습니까?");
    if (result) {
      deletePopupAdminItem(pop_seq);
    }
  };

  useEffect(() => {
    const listItems = {
      page: page,
      limit: limit,
      search_txt: searchTxt,
    };
    getPopupListAdminItem(listItems);
  }, [page, limit, searchTxt]);

  useEffect(() => {
    planDataItem("M0023");
  }, []);

  useEffect(() => {
    if (openedDetailNotice && !openedModifyNotice) {
      getPopupListAdminData?.list?.filter((item) => {
        if (item?.pop_seq === selectedNotice?.pop_seq) {
          setSelectedNotice(item);
        }
      });
    }
  }, [getPopupListAdminData, openedModifyNotice]);
  const renderTextWithLinks = (text) => {
    if (!text) return;
    return text.replaceAll("<a href", "<a target={'_blank'} href");
  };

  return (
    <AxiosNavigate>
      <div style={{ paddingBottom: "30px" }}>
        <FormProvider {...methods}>
          <Header>
            <div>팝업 관리</div>
            <div style={{ display: "flex", columnGap: "16px" }}>
              <SearchBox
                placeholder={"제목 검색"}
                onKeyDown={(e) => {
                  if (
                    e.key === "Enter" &&
                    e.nativeEvent.isComposing === false
                  ) {
                    if (
                      e.target.value.length > 0 &&
                      e.target.value.trim().length === 0
                    ) {
                      alert("제목을 입력해 주세요");
                      setSearchValue(e.target.value.trim());
                      return;
                    }
                    setSearchTxt(e.target.value);
                    setSearchValue(e.target.value.trim());
                    setPage(1);
                  }
                }}
                value={searchValue}
                onChange={(e) => {
                  setSearchValue(e.target.value);
                }}
              ></SearchBox>
              <Button
                style={{ backgroundColor: "#3279F5", color: "#fff" }}
                onClick={() => setOpenedNewNotice(true)}
              >
                작성하기
              </Button>
            </div>
          </Header>

          <TableStyle>
            <TableWrapper>
              <table>
                <thead>
                  <tr>
                    <th width={"220px"}>기간</th>
                    <th>종류</th>
                    <th width={"55%"}>제목</th>
                    <th>사용여부</th>
                    <th>등록일</th>
                  </tr>
                </thead>
                <tbody>
                  {getPopupListAdminData &&
                  getPopupListAdminData.total === 0 ? (
                    <tr style={{ height: "500px" }}>
                      <td colSpan={5}>
                        <EmptyContainer
                          falseText={"등록된 팝업 목록이"}
                          style={{ background: "#fff" }}
                        ></EmptyContainer>
                      </td>
                    </tr>
                  ) : (
                    getPopupListAdminData?.list?.map((e, i) => (
                      <tr key={i}>
                        <td>{`${moment
                          .utc(e.start_dt)
                          .format("YYYY년 M월 D일")} ~ ${moment
                          .utc(e.end_dt)
                          .format("YYYY년 M월 D일")}`}</td>
                        <td>{dataFilter(e.pop_type, planDataList)}</td>
                        <td
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          {" "}
                          <span
                            style={{
                              whiteSpace: "break-spaces",
                              textAlign: "left",
                            }}
                          >
                            {e.subject}
                          </span>
                          <ButtonComponent
                            style={{
                              backgroundColor: "#fff",
                              color: "#4A5568",
                              border: "1.125px solid #E2E8F0",
                              height: "32px",
                              width: "68px",
                              marginLeft: "8px",
                            }}
                            onClick={() => {
                              setOpenedDetailNotice(true);
                              setSelectedNotice(e);
                            }}
                          >
                            {" "}
                            {"상세보기"}
                          </ButtonComponent>
                        </td>
                        <td>{e.use_yn === "Y" ? "사용" : "중지"}</td>
                        <td>{moment.utc(e.crt_dt).format("YYYY년 M월 D일")}</td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </TableWrapper>
          </TableStyle>
          <PagingFooter>
            <Pagination
              prevPageText={<Icon src="/assets/icons/chevron-left.svg" />}
              nextPageText={<Icon src="/assets/icons/chevron-right.svg" />}
              activePage={page}
              itemsCountPerPage={limit}
              totalItemsCount={totalCount === 0 ? 1 : totalCount}
              pageRangeDisplayed={5}
              hideFirstLastPages={true}
              onChange={(e) => setPage(e)}
            />
            <PagesList
              onChange={(e) => {
                setLimit(e.value);
                setPage(1);
              }}
            ></PagesList>
          </PagingFooter>

          <SideModal
            opend={openedDetailNotice}
            closeModal={() => setOpenedDetailNotice(false)}
            header={"팝업 상세"}
            style={{ maxWidth: "800px" }}
          >
            <Side>
              <Content>
                <figure>
                  <h6>기간</h6>
                  <h5>{`${moment
                    .utc(selectedNotice?.start_dt)
                    .format("YYYY년 M월 D일")} ~ ${moment
                    .utc(selectedNotice?.end_dt)
                    .format("YYYY년 M월 D일")}`}</h5>
                </figure>
                <figure>
                  <h6>종류</h6>
                  <h5>
                    {dataFilter(
                      selectedNotice?.pop_type,
                      planDataList,
                      "dtl_cd_id",
                      "dtl_cd_nm"
                    )}
                  </h5>
                </figure>
                <figure>
                  <h6>제목</h6>
                  <h5>{selectedNotice?.subject}</h5>
                </figure>
                <figure style={{ alignItems: "stretch" }}>
                  <h6>내용</h6>
                  <h5
                    dangerouslySetInnerHTML={{
                      __html: renderTextWithLinks(selectedNotice?.content),
                    }}
                  >
                    {}
                  </h5>
                </figure>
                <figure>
                  <h6>사용여부</h6>
                  <h5>{selectedNotice?.use_yn === "Y" ? "사용" : "중지"}</h5>
                </figure>
                <figure>
                  <h6>등록일</h6>
                  <h5>
                    {moment
                      .utc(selectedNotice?.crt_dt)
                      .format("YYYY년 M월 D일")}
                  </h5>
                </figure>
              </Content>

              <div style={{ display: "grid" }} className={"grid"}>
                <ModalButton
                  onClick={() => handleSelectedDelete(selectedNotice?.pop_seq)}
                >
                  삭제
                </ModalButton>
                <ModalButton onClick={() => setOpenedModifyNotice(true)}>
                  수정
                </ModalButton>
              </div>
            </Side>
          </SideModal>

          <Modal
            opend={openedNewNotice}
            closeModal={() => {
              setOpenedNewNotice(closeModal.current);
              closeModal.current = false;
            }}
            header={"팝업 작성"}
            formRef={formRef}
            cancelText={""}
            okText={"확인"}
            widthCheck={"950px"}
            heightCheck={"950px"}
          >
            <NewNoticeForm
              ref={formRef}
              typeNames={typeNames}
              planDataList={planDataList}
              createPopupAdminItem={createPopupAdminItem}
            ></NewNoticeForm>
          </Modal>
          <Modal
            opend={openedModifyNotice}
            closeModal={() => {
              setOpenedModifyNotice(closeModal.current);
              closeModal.current = false;
            }}
            header={"팝업 수정"}
            okText={"확인"}
            cancelText={""}
            widthCheck={"950px"}
            heightCheck={"950px"}
            formRef={formRef}
          >
            <ModifyNoticeForm
              ref={formRef}
              typeNames={typeNames}
              planDataList={planDataList}
              selectedNotice={selectedNotice}
              updatePopupAdminItem={updatePopupAdminItem}
            ></ModifyNoticeForm>
          </Modal>
        </FormProvider>
      </div>
    </AxiosNavigate>
  );
};

const Button = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: fit-content;
  min-height: 32px;
  padding: 0 9px;
  justify-content: center;
  align-items: center;
  gap: 9px;
  border-radius: 6px;
  border: 1.125px solid #e2e8f0;
  background: #fff;

  color: #4a5568;
  leading-trim: both;
  text-edge: cap;
  font-size: 14px;
  cursor: pointer;

  //  margin-top: 5px;
`;
const Side = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* padding-bottom: 20px; */
  box-sizing: border-box;

  .grid {
    grid-template-columns: repeat(2, 100px);
    margin-left: auto;
    padding-bottom: 20px;
  }

  figure {
    h6 {
      min-width: 80px;
    }

    a {
      text-decoration: none;
      color: #3279f5;
    }

    h5 p {
      margin: 0;
    }
  }

  figure:nth-of-type(4) {
    * {
      width: auto !important;
      max-width: 100% !important;
    }

    table {
      width: 100% !important;
    }
  }
`;
const CheckboxIcon = styled.div`
  display: flex;
  flex: 1;
  background-color: #3279f5;
  align-self: stretch;
  cursor: pointer;
`;

const CheckboxIconInner = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CheckboxIconImage = styled.img`
  width: 12px;
  height: 12px;
`;

const TableContainer = styled.div`
  padding: 0px 32px;
  margin-top: 28px;
`;

const TableWrapper = styled.div`
  /* border: 1px solid #CBD5E0;
    border-radius: 8px;
    overflow: hidden;
    overflow-x: auto; */

  th,
  td {
    text-align: center;
  }
`;

const Table = styled.table`
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;

  th,
  td {
    text-align: center;
    padding: 8px;
    font-size: 13px;
    font-weight: 300;
    border-right: 1px solid #cbd5e0;
    border-bottom: 1px solid #cbd5e0;
    white-space: nowrap;
  }

  th {
    background-color: #f7fafc;
  }

  tr:last-child td {
    border-bottom: none;
  }

  td:first-of-type {
    text-align: center;
  }

  tr {
    height: 49px;
  }
`;

const NoticeContent = styled.div`
  h5,
  h6 {
    margin: 0;
  }

  h5 {
    color: #171923;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
  }

  h6 {
    color: #4a5568;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
  }

  hr {
    background: #edf2f7;
    color: #edf2f7;
    border: 1px solid #edf2f7;
    margin: 20px 0;
  }

  div {
    color: #4a5568;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
  }
`;

const Icon = styled.img`
  width: 16px;
`;

export default NoticeAdmin;
