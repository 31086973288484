import React, { useState, useEffect } from "react";
import AxiosNavigate from "../../../library/axios-navigate";
import { FormProvider, useForm } from "react-hook-form";
import { Header } from "../../../compontents/Header";
import SearchBox from "../../../compontents/Search";
import { PagingFooter } from "../../../compontents/PagingFooter";
import Pagination from "react-js-pagination";
import PagesList from "../../../compontents/PagesList";
import styled from "@emotion/styled";
import SelectedFilter from "../../../compontents/SelectedFilter";
import {
  getPartnersSettlement,
  updatePartnersSettlementComplete,
} from "../../../library/admin-react-query";
import { useMutation } from "@tanstack/react-query";
import moment from "moment";
import ButtonComponent from "../../../compontents/Button";
import Modal from "../../../compontents/Modal";
import { TableStyle } from "../../../library/styled-components";
import { useCallback } from "react";
const paymentOptions = [
  { value: 0, label: "전체 내역" },
  { value: 1, label: "정산완료" },
  { value: 2, label: "미정산" },
];

const PaymentPartnersAdmin = () => {
  const methods = useForm();

  const [searchTxt, setSearchTxt] = useState(""); // 엔터
  const [searchValue, setSearchValue] = useState(""); // 키보드
  const [totalCount, setTotalCount] = useState(0);
  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(1);
  const [paymentFilter, setPaymentFilter] = useState(0);

  const [selectedInfo, setSelectedInfo] = useState([]);
  const [selectedSeq, setSelectedSeq] = useState(0);
  const [openedInfo, setOpenedInfo] = useState(false);
  // 정산 정보 가져오기
  const { mutate: getSettlementMutate, data: getSettlementData } = useMutation({
    mutationKey: ["getCompanyFilter"],
    mutationFn: getPartnersSettlement,
    onSuccess: (data) => {
      setTotalCount(data.count);
    },
    onSettled: () => {},
    onError: (error) => {
      throw error;
    },
  });

  // 파트너 수익금액 정산 처리
  const { mutate: updateSettlementMutate } = useMutation({
    mutationKey: ["updatePartnersSettlementComplete"],
    mutationFn: updatePartnersSettlementComplete,
    onSuccess: (data) => {
      if (data.done) {
        alert("정산 처리가 완료되었습니다");
        handleload();
      }
    },
    onSettled: () => {},
    onError: (error) => {
      throw error;
    },
  });

  const handleload = useCallback(() => {
    const items = {
      searchTxt: searchTxt,
      filter: paymentFilter,
      page: page,
      limit: limit,
    };
    getSettlementMutate(items);
  }, [searchTxt, limit, page, paymentFilter]);

  useEffect(() => {
    handleload();
  }, [handleload]);

  return (
    <AxiosNavigate>
      <div style={{ paddingBottom: "30px" }}>
        <FormProvider {...methods}>
          <Header>
            <div>정산</div>
            <ButtonGroup>
              <SearchBox
                placeholder={"고객사, 결제구분"}
                onKeyDown={(e) => {
                  if (
                    e.key === "Enter" &&
                    e.nativeEvent.isComposing === false
                  ) {
                    if (
                      e.target.value.length > 0 &&
                      e.target.value.trim().length === 0
                    ) {
                      alert("제목을 입력해 주세요");
                      setSearchValue(e.target.value.trim());
                      return;
                    }

                    setSearchTxt(e.target.value);
                    setSearchValue(e.target.value.trim());
                    setPage(1);
                    // handleload();
                  }
                }}
                value={searchValue}
                onChange={(e) => {
                  setSearchValue(e.target.value);
                }}
              ></SearchBox>
              <SelectedFilter
                placeholder={paymentOptions ? paymentOptions[0].label : ""}
                options={paymentOptions}
                onChange={(e) => {
                  setPaymentFilter(e.value);
                  setPage(1);
                  setLimit(20);
                  setSearchTxt("");
                  // handleload();
                }}
                value={paymentOptions.find((e) => e.value === paymentFilter)}
              ></SelectedFilter>
            </ButtonGroup>
          </Header>
          <TableStyle>
            <TableWrapper>
              <table>
                <thead>
                  <tr>
                    <th width={"70px"}>구분</th>
                    <th width={"150px"}>정산월</th>
                    <th width={"300px"}>고객사</th>
                    <th>결제구분</th>
                    <th>현장 수</th>
                    <th>
                      정산 대상 <br />
                      인력(명)
                    </th>
                    <th>
                      일사 <br />
                      수수료
                    </th>
                    <th>
                      파트너 <br />
                      수액금액
                    </th>
                    <th width={"200px"}>고객사 정산일</th>
                    <th width={"200px"}>정산일</th>
                  </tr>
                </thead>
                <tbody>
                  {getSettlementData?.list &&
                    getSettlementData?.list.map((e, i) => (
                      <tr key={e.pg_seq}>
                        <td>{i + limit * (page - 1) + 1}</td>
                        <td>{moment.utc(e?.pay_dt).format("YYYY년 M월")}</td>
                        <td
                          className="table-left spaces"
                          style={{ width: "300px" }}
                        >
                          {e.corp_nm}
                          {e.withdrawal_dt && (
                            <>
                              <br />
                              <em style={{ color: "red" }}>(탈퇴)</em>
                            </>
                          )}
                        </td>
                        <td className="table-left">플랜({e.plan_nm})</td>
                        <td>{e.site_cnt}</td>
                        <td>{e.user_cnt}</td>
                        <td className="table-right">
                          {e.fee_amt.toLocaleString()}
                        </td>
                        <td className="table-right">
                          {e.partner_fee_amt.toLocaleString()}
                        </td>
                        <td style={{ width: "200px" }}>
                          {e?.done_dt
                            ? moment.utc(e?.done_dt).format("YYYY년 M월 D일")
                            : ""}
                        </td>
                        <td style={{ width: "200px" }}>
                          {e?.done_dt ? (
                            e.partners_pay_dt !== null ? (
                              moment
                                .utc(e?.partners_pay_dt)
                                .format("YYYY년 M월 D일")
                            ) : (
                              <ButtonComponent
                                style={{
                                  backgroundColor: !e.withdrawal_dt
                                    ? "#fff"
                                    : "#dad9d9",
                                  color: "#4A5568",
                                  border: "1.125px solid #E2E8F0",
                                  height: "32px",
                                  width: "68px",
                                  margin: "auto",
                                }}
                                onClick={(event) => {
                                  event.preventDefault();
                                  if (e.withdrawal_dt) {
                                    alert("탈퇴한 고객사입니다.");
                                    return;
                                  }
                                  setOpenedInfo(true);
                                  setSelectedInfo(e?.partners_info);
                                  setSelectedSeq(e.pg_seq);
                                }}
                              >
                                정산
                              </ButtonComponent>
                            )
                          ) : (
                            ""
                          )}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </TableWrapper>
          </TableStyle>
          <PagingFooter>
            <Pagination
              prevPageText={<Icon src="/assets/icons/chevron-left.svg" />}
              nextPageText={<Icon src="/assets/icons/chevron-right.svg" />}
              activePage={page}
              itemsCountPerPage={limit}
              totalItemsCount={totalCount === 0 ? 1 : totalCount}
              pageRangeDisplayed={5}
              hideFirstLastPages={true}
              onChange={(e) => {
                setPage(e);
                // handleload();
              }}
            />
            <PagesList
              onChange={(e) => {
                setLimit(e.value);
                setPage(1);
                // handleload();
                // handleBoard();
              }}
            ></PagesList>
          </PagingFooter>
        </FormProvider>

        <Modal
          opend={openedInfo}
          closeModal={() => {
            setOpenedInfo(false);
          }}
          handleOk={() => {
            updateSettlementMutate(selectedSeq);
          }}
          header={"정산 처리"}
          cancelText={"취소"}
          okText={"확인"}
          widthCheck={"650px"}
        >
          <div>
            <TableStyle
              style={{ margin: "0px", padding: "0px", width: "100%" }}
            >
              <TableWrapper style={{ width: "100%" }}>
                <table>
                  <thead>
                    <tr>
                      <th width={"40px"}>구분</th>
                      <th width={"100px"}>이름</th>
                      <th width={"200px"}>ID</th>
                      <th width={"80px"}>
                        리베이트
                        <br />
                        비율
                      </th>

                      <th>
                        파트너 <br />
                        수액금액
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedInfo &&
                      selectedInfo.map((e, i) => (
                        <tr key={e.partners_seq} style={{ height: "unset" }}>
                          <td style={{ minWidth: "40px" }}>{i + 1}</td>
                          <td>{e.partners_nm}</td>
                          <td style={{ minWidth: "200px" }}>{e.partners_id}</td>
                          <td style={{ minWidth: "80px" }}>
                            {e.rebate_type === "FR" ? `${e.rebate_amt}%` : ""}
                          </td>

                          <td className="table-right">
                            {e.pay_amt.toLocaleString()}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </TableWrapper>
            </TableStyle>
          </div>
        </Modal>
      </div>
    </AxiosNavigate>
  );
};
const ButtonGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;
const TableContainer = styled.div`
  padding: 0px 32px;
  margin-top: 28px;

  tr {
    height: 37px;
  }
`;

const TableWrapper = styled.div`
  /* border: 1px solid #cbd5e0;
  border-radius: 8px;
  overflow: hidden;
  overflow-x: auto; */

  th,
  td {
    text-align: center;
  }

  tr {
    height: 37px;
  }
`;

const Table = styled.table`
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;

  th,
  td {
    text-align: center;
    padding: 2px;
    font-size: 13px;
    font-weight: 300;
    border-right: 1px solid #cbd5e0;
    border-bottom: 1px solid #cbd5e0;
    white-space: nowrap;
  }

  th {
    background-color: #f7fafc;
  }

  tr:last-child td {
    border-bottom: none;
  }

  td:first-of-type {
    text-align: center;
  }
  thead {
    height: unset;
  }
`;
const Icon = styled.img`
  width: 16px;
`;
export default PaymentPartnersAdmin;
