import styled from "@emotion/styled";
import React, { forwardRef } from "react";
import dayjs from "dayjs";
import PrintSignComponent from "../PrintSignComponent";

const tdCustomStyle = { textAlign: "right", paddingRight: "3px" };

const ExpenseRequestPagePrint = forwardRef((props, ref) => {
  const data = props.data;
  const list = props.data.imprests;
  const site_seq = props.siteSeq;

  const filteredList3 = list.filter((item) => {
    const firstDigit = String(item.sort)[0]; // 숫자의 첫 번째 자리를 가져옵니다.
    return firstDigit === "3";
  });

  const filteredList4 = list.filter((item) => {
    const firstDigit = String(item.sort)[0]; // 숫자의 첫 번째 자리를 가져옵니다.
    return firstDigit === "4";
  });

  return (
    <Container ref={ref} className="print-portrait">
      <Header>
        <div>
          <Title>
            {dayjs(data.imp_dt).format("YYYY년 M월")}분 전도금 신청내역서
          </Title>
          <HeaderGroup>
            <Badge>
              <Label>작성일</Label>
              <Text>{dayjs(data.imp_dt).format("YYYY년 M월 D일")}</Text>
            </Badge>

            <Badge>
              <Label>담당자</Label>
              <Text>{data.user_nm}</Text>
            </Badge>

            <Badge>
              <Label>현장소장</Label>
              <Text>{data.user_nm}</Text>
            </Badge>
            <Badge>
              <Label>현장명</Label>
              <Text>{data.site_nm}</Text>
            </Badge>
          </HeaderGroup>
        </div>

        <PrintSignComponent seq={site_seq}></PrintSignComponent>

        {/* <Text>
            <strong>현장명</strong> <span>파주운정자이 전기공사</span>
          </Text> */}
      </Header>

      <TableContainer>
        <TableWrapper>
          <Table>
            <thead style={{ height: "24px" }}>
              <tr>
                <th colSpan={3} className={"table-left"}>
                  항목
                </th>
                <th>전월 신청액</th>
                <th>금월 신청액</th>
                <th>비고</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colSpan={3} className={"table-left"}>
                  {list[0].class_nm}
                </td>
                <td className={"table-right"} style={tdCustomStyle}>
                  {list[0].bm_imp_amt.toLocaleString()}
                </td>
                <td className={"table-right"} style={tdCustomStyle}>
                  {list[0].imp_amt.toLocaleString()}
                </td>
                <td></td>
              </tr>

              <tr>
                <td colSpan={3} className={"table-left"}>
                  {list[1].class_nm}
                </td>
                <td className={"table-right"} style={tdCustomStyle}>
                  {list[1].bm_imp_amt.toLocaleString()}
                </td>
                <td className={"table-right"} style={tdCustomStyle}>
                  {list[1].imp_amt.toLocaleString()}
                </td>
                <td></td>
              </tr>

              <tr>
                <td
                  rowSpan={filteredList3.length + filteredList4.length + 2}
                  style={{ writingMode: "vertical-rl", width: "20px" }}
                >
                  전도금
                </td>
                <td colSpan={2} className={"table-left"}>
                  구분
                </td>
                <td></td>
                <td></td>
                <td></td>
              </tr>

              {/* 정기적 */}

              {!!filteredList3.length &&
                filteredList3.map((e, i) => (
                  <tr key={i}>
                    {i === 0 ? (
                      <>
                        <td
                          rowSpan={filteredList3.length}
                          style={{ writingMode: "vertical-rl", width: "20px" }}
                        >
                          정기적
                        </td>
                        <td className={"table-left"}>{e.class_nm}</td>
                        <td className={"table-right"} style={tdCustomStyle}>
                          {e.bm_imp_amt.toLocaleString()}
                        </td>
                        <td className={"table-right"} style={tdCustomStyle}>
                          {e.imp_amt.toLocaleString()}
                        </td>
                        <td></td>
                        {/*<td></td>*/}
                      </>
                    ) : i === filteredList3.length - 1 ? (
                      <>
                        <th className={"table-left"}>{e.class_nm}</th>
                        <th className={"table-right"} style={tdCustomStyle}>
                          {e.bm_imp_amt.toLocaleString()}
                        </th>
                        <th className={"table-right"} style={tdCustomStyle}>
                          {e.imp_amt.toLocaleString()}
                        </th>
                        <th></th>
                        {/*<th></th>*/}
                      </>
                    ) : (
                      <>
                        <td className={"table-left"}>{e.class_nm}</td>
                        <td className={"table-right"} style={tdCustomStyle}>
                          {e.bm_imp_amt.toLocaleString()}
                        </td>
                        <td className={"table-right"} style={tdCustomStyle}>
                          {e.imp_amt.toLocaleString()}
                        </td>
                        <td></td>
                      </>
                    )}
                  </tr>
                ))}

              {/* 비정기적 */}

              {!!filteredList4.length &&
                filteredList4.map((e, i) => (
                  <tr key={i}>
                    {i === 0 ? (
                      <>
                        <td
                          rowSpan={filteredList4.length}
                          style={{ writingMode: "vertical-rl", width: "20px" }}
                        >
                          비정기적
                        </td>
                        <td className={"table-left"}>{e.class_nm}</td>
                        <td className={"table-right"} style={tdCustomStyle}>
                          {e.bm_imp_amt.toLocaleString()}
                        </td>
                        <td className={"table-right"} style={tdCustomStyle}>
                          {e.imp_amt.toLocaleString()}
                        </td>
                        <td></td>
                      </>
                    ) : i === filteredList4.length - 1 ? (
                      <>
                        <th className={"table-left"}>{e.class_nm}</th>
                        <th className={"table-right"} style={tdCustomStyle}>
                          {e.bm_imp_amt.toLocaleString()}
                        </th>
                        <th className={"table-right"} style={tdCustomStyle}>
                          {e.imp_amt.toLocaleString()}
                        </th>
                        <th></th>
                        {/*<th></th>*/}
                      </>
                    ) : (
                      <>
                        <td className={"table-left"}>{e.class_nm}</td>
                        <td className={"table-right"} style={tdCustomStyle}>
                          {e.bm_imp_amt.toLocaleString()}
                        </td>
                        <td className={"table-right"} style={tdCustomStyle}>
                          {e.imp_amt.toLocaleString()}
                        </td>
                        <td></td>
                      </>
                    )}
                  </tr>
                ))}
            </tbody>
            <tfoot>
              <tr>
                <th colSpan={3} className={"table-left"}>
                  총 사용 및 총 청구액
                </th>
                <th className={"table-right"} style={tdCustomStyle}>
                  {list[list.length - 1].bm_imp_amt.toLocaleString()}
                </th>
                <th className={"table-right"} style={tdCustomStyle}>
                  {list[list.length - 1].imp_amt.toLocaleString()}
                </th>
                <th></th>
              </tr>
            </tfoot>
          </Table>
        </TableWrapper>
      </TableContainer>

      <Text style={{ marginTop: "8px", color: "#2D3748" }}>
        * 비용집행 포함된 내용으로, 지출결의서는 본 청구서로 갈음함.
      </Text>
    </Container>
  );
});

const Container = styled.div`
  // border: 1px solid #E2E8F0;
  // border: 1px solid #FFFFFF;
  background: #fff;
  padding: 20px 24px;

  @page {
    size: A4 portrait !important;
    margin-top: 20px;
    margin-bottom: 25px;
  }
  @media print {
    table th {
      background-color: #edf2f7 !important;
      -webkit-print-color-adjust: exact;
    }

    .table-wrapper {
      break-after: page;
    }
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  width: 100%;
`;
const CheckGroup = styled.div`
  display: flex;
  column-gap: 7px;

  > div {
    width: 43px;
    height: 43px;
    border-radius: 2px;
    background-color: #f7fafc;
    overflow: hidden;
  }

  span {
    background-color: #edf2f7;
    line-height: 13px;
    text-align: center;
    display: block;
    color: #4a5568;
    font-size: 7px;
    font-weight: 500;
  }

  @media print {
    > div {
      background-color: #edf2f7 !important;
      -webkit-print-color-adjust: exact;
    }
  }
`;
const Title = styled.div`
  color: #171923;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  margin-bottom: 6px;
`;

const HeaderGroup = styled.div`
  display: flex;
`;

const Badge = styled.div`
  display: flex;
  padding: 4px 6px;
  align-items: flex-start;
  border-radius: 6px;
  background: #f7fafc;

  &:not(:last-child) {
    margin-right: 4px;
  }
`;

const Label = styled.div`
  color: #718096;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 8px;
  font-style: normal;
  font-weight: 500;
  line-height: 8px;
  margin-right: 4px;
`;

const Text = styled.div`
  color: #171923;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 8px;
  font-style: normal;
  font-weight: 500;
  line-height: 8px;
`;

const TableContainer = styled.div``;

const TableWrapper = styled.div`
  // border-radius: 8px;
  overflow: hidden;
  border-radius: 6px;
  border: 1px solid #000;
  overflow-x: auto;
`;

const Table = styled.table`
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;

  th,
  td {
    text-align: left;
    font-size: 9px;
    font-weight: 500;
    white-space: nowrap;
    text-align: center;
    border-bottom: 1px solid #000;
    border-right: 1px solid #000;

    &:not(:last-child) {
      //border-bottom: none;
    }
  }

  th {
    background-color: #edf2f7;
    color: #4a5568;
    font-style: normal;
    font-weight: 500;
    line-height: 12px;
    padding: 2px;
  }

  td {
    color: #1a202c;
    padding: 2px;
  }

  tr:last-child td {
    //border-bottom: none;
  }

  tfoot th {
    border-bottom: none;
    font-weight: bold;
    text-align: right;
  }

  tr {
    //height: 20px;
  }
`;

const Signature = styled.div`
  display: flex;
  width: 43px;
  height: 43px;
  padding-bottom: 0px;
  justify-content: center;
  align-items: flex-start;
  border-radius: 2px;
  background: #f7fafc;

  &:not(:last-child) {
    margin-right: 6px;
  }
`;

const SignatureTitle = styled.div`
  color: #4a5568;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 7px;
  font-style: normal;
  font-weight: 500;
  line-height: 7px;
  display: flex;
  width: 100%;
  height: 13px;
  justify-content: center;
  align-items: center;
  background: #edf2f7;
`;

export default ExpenseRequestPagePrint;
