import AppLayout from "./app-layout";
import Login from "./pages/login";
import Dashboard from "./pages/dashboard";
import Report from "./pages/report";
import Site from "./pages/site";
import LaborAttendance from "./pages/labor/attendance";
import LaborAttendanceStatus from "./pages/labor/attendance-status";
import LaborEmploy from "./pages/labor/employ";
import LaborPaymentStatement from "./pages/labor/payment-statement";
import LaborStatementSubmission from "./pages/labor/statement-submission";
import LaborTransferRequest from "./pages/labor/transfer-request";
import ExpenseStatus from "./pages/expense/status";
import ExpenseRequest from "./pages/expense/request";
import SettingCompany from "./pages/setting/company";
import SettingEmploy from "./pages/setting/employ";
import SettingRole from "./pages/setting/role";
import SettingNotice from "./pages/setting/notice";
import SettingAdmin from "./pages/setting/admin";
import Company from "./pages/admin/company";
import User from "./pages/admin/user";
import LaborMonthlyPaymentStatement from "./pages/labor/monthly-payment-statement";
import SignUpNew from "./pages/signup_new";
import Welcome from "./pages/welcome";
import UpgradePlan from "./pages/upgrade-plan";
import UpdateCompanyInfo from "./pages/update-company-info";
import PaymentInformation from "./pages/setting/payment-information";
import FindIdPassword from "./pages/find-id-password";
import SiteAdmin from "./pages/admin/site";
import LaborInsuranceDetail from "./pages/labor/insurance-detail";
import PaymentInformationAdmin from "./pages/admin/payment-information";
import LaborDownloadReport from "./pages/labor/download-report-data";
import NoticeAdmin from "./pages/admin/notice";
import OutsourcingManagement from "./pages/outsourcing/outsourcing-management";
import DashBoardAdmin from "./pages/admin/dashboard";
import DiscountApplyAdmin from "./pages/admin/discount-apply";
import DiscountRuleAdmin from "./pages/admin/discount-rule";
import DashBoardPartnersAdmin from "./pages/admin/partners/dashboard";
import PartnersAdmin from "./pages/admin/partners/partners";
import PaymentPartnersAdmin from "./pages/admin/partners/payment-information";
import CustomerPartnersAdmin from "./pages/admin/partners/customer";

const routes = [
  {
    path: "/",
    element: <AppLayout />,
    children: [
      {
        path: "admin/company",
        element: <Company />,
      },
      {
        path: "/admin",
        element: <SettingAdmin />,
      },
      {
        path: "admin/site",
        element: <SiteAdmin />,
      },
      {
        path: "admin/user",
        element: <User />,
      },
      {
        path: "admin/discount-rule",
        element: <DiscountRuleAdmin />,
      },
      {
        path: "admin/discount-apply",
        element: <DiscountApplyAdmin />,
      },
      {
        path: "admin/payment-information",
        element: <PaymentInformationAdmin />,
      },
      {
        path: "admin/notice",
        element: <NoticeAdmin />,
      },
      {
        path: "admin/board",
        element: <DashBoardAdmin />,
      },
      {
        path: "partners/admin/customer",
        element: <CustomerPartnersAdmin />,
      },
      {
        path: "partners/admin",
        element: <PartnersAdmin />,
      },
      {
        path: "partners/admin/payment-information",
        element: <PaymentPartnersAdmin />,
      },
      {
        path: "partners/admin/board",
        element: <DashBoardPartnersAdmin />,
      },
      {
        path: "labor/attendance",
        element: <LaborAttendance />,
      },
      {
        path: "labor/attendance-status",
        element: <LaborAttendanceStatus />,
      },
      {
        path: "labor/employ",
        element: <LaborEmploy />,
      },
      {
        path: "labor/payment-statement",
        element: <LaborPaymentStatement />,
      },
      {
        path: "labor/monthly-payment-statement",
        element: <LaborMonthlyPaymentStatement />,
      },
      {
        path: "labor/statement-submission",
        element: <LaborStatementSubmission />,
      },
      {
        path: "labor/transfer-request",
        element: <LaborTransferRequest />,
      },
      {
        path: "labor/insurance-detail",
        element: <LaborInsuranceDetail />,
      },
      {
        path: "labor/download-report",
        element: <LaborDownloadReport />,
      },
      {
        path: "expense/status",
        element: <ExpenseStatus />,
      },
      {
        path: "expense/request",
        element: <ExpenseRequest />,
      },
      {
        path: "/outsourcing/management",
        element: <OutsourcingManagement />,
      },
      {
        path: "setting/company",
        element: <SettingCompany />,
      },
      {
        path: "setting/employ",
        element: <SettingEmploy />,
      },
      {
        path: "setting/role",
        element: <SettingRole />,
      },
      {
        path: "setting/notice",
        element: <SettingNotice />,
      },
      {
        path: "setting/payment-information",
        element: <PaymentInformation />,
      },
      {
        path: "",
        element: <Dashboard />,
      },
      {
        path: "report",
        element: <Report />,
      },
      {
        path: "site",
        element: <Site />,
      },
    ],
  },
  {
    path: "login",
    element: <Login />,
  },
  {
    path: "find-account/:text",
    element: <FindIdPassword />,
  },
  {
    path: "signup-new",
    element: <SignUpNew />,
  },
  {
    path: "company-info",
    element: <UpdateCompanyInfo />,
  },
  {
    path: "welcome",
    element: <Welcome />,
  },
  {
    path: "upgrade-plan",
    element: <UpgradePlan />,
  },
];

export { routes };
