import React, { forwardRef } from "react";
import styled from "@emotion/styled";

const LaborContractPagePrint = forwardRef((props, ref) => {
  const data = props.data;
  const createMarkup = (content) => {
    return { __html: content };
  };
  return (
    <Container ref={ref} className="print-portrait">
      <Contract
        dangerouslySetInnerHTML={createMarkup(data.contract)}
      ></Contract>
    </Container>
  );
});

const Container = styled.div`
  //background: #FFF;
  //padding: 20px 24px;

  table td:not(.value),
  table th {
    //padding: 2px 0 !important;
  }

  table .value {
    align-items: center;
  }

  @page {
    size: A4 portrait !important;
    //margin-top: 20px;
    //margin-bottom: 25px;
    margin: 0 !important;
    padding: 0 !important;
  }

  @media print {
    padding: 0 !important;
    margin: 0 !important;
    table th {
      background-color: #edf2f7 !important;
      -webkit-print-color-adjust: exact;
    }

    .table-wrapper {
      //break-inside: auto;
      break-after: page;
    }

    .signature .value {
      height: 100% !important;
    }
  }

  .container {
    min-width: 990px;
  }

  .signature .value {
    height: 30px !important;
    box-sizing: border-box;
  }

  .detail {
    line-height: 15px;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

const Contract = styled.div`
  //font-size: 13px;
`;

const InfoWrap = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 10px;
  box-sizing: border-box;
  border-bottom: 1px solid #e2e8f0;

  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;

    em {
      font-weight: 500;
      font-size: 19px;
    }

    span {
      font-weight: 350;
      font-size: 13px;
    }

    //justify-content: space-around;
  }

  img {
    width: 100px;
    height: 100px;
  }
`;

const Footer = styled.div`
  span.box {
    padding: 10px 22px;
    box-sizing: border-box;
    border-radius: 5px;
    background-color: #edf2f7;
    width: 100%;
    display: block;
    font-weight: normal;
    margin-top: 25px;
  }

  > span {
    font-size: 14px;
  }

  .date {
    display: inline-block;
    margin-top: 10px;
    margin-bottom: 10px;
    font-weight: bold;
  }
`;

const UserWrap = styled.div``;

export default LaborContractPagePrint;
