import styled from "@emotion/styled";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axiosInstance from "../library/axios-index";
import { addCommas } from "../bin/common";
import moment from "moment";
const selectItemStyle = {
  style: { color: "#ffffff", backgroundColor: "#3279f5" },
  imageColor: "white",
  lineColor: "#E2E8F0",
  itemColor: "#fff",
  textColor: "#fff",
};
const defaultItemStyle = {
  style: { color: "#333333", backgroundColor: "#ffffff" },
  imageColor: "gray",
  lineColor: "#E2E8F0",
  itemColor: "#333",
  textColor: "#3279F5",
};
const basicItems = [
  "근태 관리",
  "노무 관리",
  "공사 일보",
  "지출 관리",
  "외주 관리",
  "장비 관리",
  "자재 관리",
  "구인 공고 (유료)",
];
const proItems = [
  "4대보험 산출",
  "근태 관리",
  "노무 관리",
  "공사 일보",
  "지출 관리",
  "외주 관리",
  "장비 관리",
  "자재 관리",
  "구인 공고 (유료)",
];
function Welcome() {
  const navigate = useNavigate();
  const location = useLocation();
  const addDay = moment().add(60, "days").format("(YYYY년 M월 D일까지)");
  const [items, setItems] = useState(null);
  const [text, setText] = useState({
    header: "Free",
    title: "무료",
    siteCount: "1개 현장",
    amount: "0원",
    contentStart: "현장관리 솔루션을 사용해본 적 없고",
    contentMid: "현장의 수가 1개",
    contentEnd: "현장사무실에 추천합니다.",
  });

  useEffect(() => {
    const planInfo = { ...location.state };

    if (planInfo) {
      (async () => {
        let planData = await axiosInstance.post(`/code/codeList`, {
          lang: "ko",
          uuid: "string",
          mst_cd_id: "M0015",
        });

        planData = planData.data.result.find(
          (e) => e.dtl_cd_id === planInfo.plan
        );
        text.header = planData.dtl_cd_desc;
        text.title = planData.dtl_cd_nm;
        text.amount = addCommas(planData.attribute1) + "원";
        text.siteCount =
          Number(planData.attribute2) <= 5
            ? planData.attribute2 + "개 현장"
            : "현장 무제한";
        text.contentStart =
          planData.dtl_cd_id == "M0015D0001"
            ? "현장관리 솔루션을 사용해본 적 없고"
            : "체계적인 현장관리에 관심이 있으면서";

        if (planData.attribute2 <= 1) {
          text.contentMid = "현장의 수가 1개";
          text.contentEnd = "현장사무실에 추천합니다";
        } else if (planData.attribute2 <= 5) {
          text.contentMid = "현장의 수가 5개 이하";
          text.contentEnd = "중소기업에 추천합니다.";
        } else {
          text.contentMid = "현장의 수가 5개 초과";
          text.contentEnd = "중소/중견기업에 추천합니다.";
        }
        setText((prevState) => ({ ...prevState, text }));
        if (planData.dtl_cd_id === "M0015D0002") {
          setItems(basicItems);
        } else {
          setItems(proItems);
        }
      })();
    }
  }, []);
  return (
    <Container>
      <Header>
        <h1 style={{ marginBottom: "15px" }}>환영합니다!</h1>
        {/*<h2 style={{ marginBottom: "15px" }}>init_fee_amt회원가입을 축하합니다.</h2>*/}
      </Header>
      <Background>
        <div style={{ marginTop: "20px" }} />
        <h4>스마트폰에서 일사 앱으로 현장을 관리하세요.</h4>
        <h4>
          (앱 첫화면 상단 오른쪽{" "}
          <span style={{ color: "#3279F5" }}>“관리자로 전환"</span>을 클릭해
          현장을 앱으로 관리할 수 있습니다.)
        </h4>
        <Box>
          <BoxTitle>{text?.header}</BoxTitle>
          <h2>{text?.title}</h2>
          {text?.title === "무료" && <h5>{addDay}</h5>}

          <div
            style={{
              height: "1px",
              width: "160px",
              margin: "10px",
              backgroundColor: "#E2E8F0",
            }}
          />
          <div style={{ display: "flex" }}>
            <h1 style={{ marginBottom: "5px" }}>{text?.amount}</h1>
            <div
              style={{ fontSize: "12px", marginTop: "23px", marginLeft: "5px" }}
            >
              {" "}
              / 월
            </div>
          </div>
          <h5 style={{ marginBottom: "10px" }}>근로자당 (부가세 별도)</h5>
          {/* <h4>{text.contentStart}</h4>
          <h4>
            운영중인{" "}
            <span style={{ color: "#3279F5", fontWeight: "600" }}>
              {text.contentMid}
            </span>
            인
          </h4>
          <h4>{text.contentEnd}</h4> */}
          <div
            style={{
              height: "1px",
              width: "160px",
              margin: "10px",
              backgroundColor: "#E2E8F0",
            }}
          />
          <List>
            {items?.map((item, index) => (
              <Item
                key={index}
                style={
                  item === "4대보험 산출"
                    ? { gridColumn: "1/3", color: "red", fontWeight: "bold" }
                    : {}
                }
              >
                <Checkmark src={`/assets/icons/check-gray.svg`} />
                {item}
              </Item>
            ))}
          </List>
          <BoxButton
            onClick={() => {
              localStorage.setItem("admin_token", "");
              navigate("/login");
            }}
          >
            시작하기
          </BoxButton>
        </Box>
        <div style={{ marginTop: "30px" }} />
        <h5 style={{ display: "flex", justifyContent: "center" }}>
          <Icon src="/assets/icons/alert-circle-gray.svg" />
          &nbsp;&nbsp;근로계약서가 유효하려면&nbsp;
          <span style={{ fontWeight: "700" }}>‘법인 직인'</span>&nbsp;등록이
          필수입니다.
        </h5>
        <h5>아래 사용메뉴얼을 참고하세요.</h5>
        <Manual
          onClick={() => {
            window.open(
              "https://bucket-9uc28v.s3.ap-northeast-2.amazonaws.com/ilsa/manual/ilsa_setup_manual.pdf"
            );
          }}
        >
          사용메뉴얼
        </Manual>
      </Background>
    </Container>
  );
}

export default Welcome;
const Checkmark = styled.img`
  width: 14px;
  margin-right: 5px;
`;
const Container = styled.div`
  background: #f7fafc;
  height: 100vh;
  overflow: hidden;
`;

export const Header = styled.div`
  height: 120px;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;

  h1 {
    font-size: 24px;
    font-weight: 700;
    color: #333333;
    margin: 0;
  }

  h2 {
    font-size: 16px;
    font-weight: 400;
    color: #333333;
    margin: 0;
  }
`;
export const List = styled.ul`
  display: grid;
  grid-template-columns: repeat(2, auto);
  gap: 10px;
  list-style-type: none;
  padding: 0;
`;

export const Item = styled.li`
  display: flex;
  align-items: center;
  font-size: 10px;
  color: #333; /* 검정색 텍스트 */

  &.white-text {
    color: #fff; /* 흰색 텍스트 */
  }
`;
export const Background = styled.div`
  height: 100%;
  width: 100%;
  background-color: #ebf2fe;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;

  h4 {
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    margin-top: 10px;
  }

  h5 {
    font-size: 12px;
    font-weight: 400;
    color: #818791;
    margin-top: 5px;
  }
`;

export const Box = styled.div`
  width: 200px;
  height: 380px;
  //opacity: 0;
  color: #333333;
  background-color: #ffffff;
  border-radius: 16px;
  margin-top: 20px;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    font-size: 26px;
    font-weight: 700;
    margin-top: 10px;
  }

  h2 {
    margintop: 20px;
    margin-bottom: 4px;
    font-size: 20px;
    font-weight: 500;
  }

  h4 {
    height: 20px;
    font-size: 11px;
    font-weight: 400;
    margin: 0;
    color: #4a5568;
  }

  h5 {
    font-size: 10px;
    font-weight: 400;
    margin: 0;
    color: #4a5568;
  }
`;

export const BoxTitle = styled.div`
  width: 62px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ebf2fe;
  color: #3279f5;
  font-size: 14px;
  font-weight: 400;
  border-radius: 10px;
  margin-top: 20px;
`;

export const BoxButton = styled.div`
  width: 160px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #3279f5;
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  border-radius: 10px;
  margin-top: 20px;
  cursor: pointer;
`;

export const Manual = styled.div`
  width: 84px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e2e8f0;
  margin-top: 20px;
  cursor: pointer;

  color: #818791;
  font-size: 12px;
  font-weight: 500;
  margin-top: 10px;

  border-radius: 4px;
  border-style: solid;
  border-color: #cbd5e0;
  border-width: 1px;
`;

const Icon = styled.img`
  width: 14px;
`;
